import { makeStyles } from '@material-ui/core';

import { Theme } from 'theme/v4';

export const useStyles = makeStyles((theme: Theme) => ({
  incidentTitle: {
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.gray[900],
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '80%'
  },
  incidentCauseTitle: {
    margin: theme.spacing(2, 0, 2),
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.gray[900]
  },
  incidentCause: {
    margin: theme.spacing(2, 0, 2),
    color: theme.palette.gray[500]
  },
  incidentDateTitle: {
    color: theme.palette.gray[900],
    fontWeight: theme.typography.fontWeightMedium
  },
  incidentDate: {
    color: theme.palette.gray[500]
  },
  incidentIdTitle: {
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.gray[900]
  },
  incidentIdLink: {
    color: theme.palette.gray[500],
    fontSize: 14,
    fontWeight: 400
  },
  ellipse: {
    width: '4px',
    height: '4px',
    backgroundColor: theme.palette.gray[700],
    borderRadius: '50%',
    marginRight: theme.spacing(1)
  },
  divider: {
    backgroundColor: theme.palette.gray[100],
    margin: theme.spacing(2, 0, 6)
  },
  incidentDivider: {
    backgroundColor: theme.palette.gray[100],
    margin: theme.spacing(3, 0, 3)
  },
  input: {
    height: theme.spacing(5.5),

    '&:hover fieldset': {
      borderColor: `${theme.palette.primary.main} !important`
    },

    '& fieldset': {
      borderWidth: 1,
      borderColor: theme.palette.gray[200],
      borderStyle: 'solid'
    }
  },
  filterButton: {
    minWidth: 50,
    padding: theme.spacing(1),
    '& span': {
      width: 'fit-content'
    },
    '& svg': {
      width: '1.5rem',
      height: '1.5rem'
    }
  },
  firstChild: {
    marginLeft: theme.spacing(1)
  },
  textNoResult: {
    fontWeight: 500,
    color: '#515151',
    fontSize: 20
  },
  menuButton: {
    marginLeft: 'auto',
    minWidth: 42,
    height: 34,
    border: '1px solid #595959',
    padding: theme.spacing(1),
    '& span': {
      width: 'fit-content'
    },
    '& svg': {
      width: '1.5rem',
      height: '1.5rem'
    }
  },
  menuItem: {
    '&:hover': {
      color: theme.palette.green[400],
      backgroundColor: theme.palette.green[50]
    }
  },
  paperProps: {
    width: '220px'
  },
  badgeContent: {
    backgroundColor: '#CDF4E2',
    color: '#515151',
    border: '1px solid #5FBC91',
    fontWeight: 500
  },
  textFilters: {
    fontWeight: 500,
    lineHeight: '26px',
    marginLeft: '3px'
  },
  backgroundButton: {
    backgroundColor: '#ECFDF5'
  },
  button: {
    border: '1px solid #5FBC91'
  },
  responders: {
    display: 'flex',
    gap: 10
  },
  respondersTextTooltip: {
    marginLeft: 8
  },
  responderBox: {
    display: 'flex',
    gap: 3
  },
  responderLabel: {
    fontWeight: 500,
    fontSize: 14
  },
  responderName: (props: { maxWidth: number }) => ({
    fontSize: 14,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: props.maxWidth
  })
}));
