import { ChangeEvent, useState } from 'react';

import { DndContext, DragEndEvent } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { SortableContext } from '@dnd-kit/sortable';
import { Box, Paper, TextField, Typography } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import { Autocomplete, ToggleButtonGroup } from '@material-ui/lab';
import { useFieldArray, UseFormMethods } from 'react-hook-form';
import { ExternalIntegration } from 'types/external/ExternalService';
import { MultiFlow } from 'types/external/MultiFlow';
import { Resource as ResourceData } from 'types/external/Resource';

import Button from 'componentsV4/TextButton/Button';
import useExternalIntegration from 'hooks/queriesGraphQL/useExternalIntegration';
import { useMultiFlows } from 'hooks/queriesGraphQL/useMultiFlows';
import { useResources } from 'hooks/queriesGraphQL/useResources';
import { FormServiceValues, FormValues } from 'views/ServicesHub/layouts/StatusPage';

import { Input } from '../Input';

import { Options } from './Options';
import { Resource } from './Resource';
import { useStyles } from './styles';

type GroupProps = {
  form: UseFormMethods<FormValues>;
  index: number;
  onDelete?: () => void;
  disabled?: boolean;
};

export function Group({ form, index, onDelete, disabled }: GroupProps) {
  const classes = useStyles();

  const { control, register } = form;

  // DATA
  const { externalList } = useExternalIntegration();

  const [{ data: resourcesData = { getResourcesFromIndexer: { data: [] } } }] = useResources({
    page: 1,
    perPage: 1000,
    query: ''
  });

  const [{ data: syntheticsData = { multiflows: { data: [] } } }] = useMultiFlows({
    page: 1,
    perPage: 1000,
    query: ''
  });

  // VARIABLES
  const [selectedValue, setSelectedValue] = useState<string | null>(null);
  const [inputText, setInputText] = useState<string>('');

  const {
    fields: fieldServices,
    append: appendService,
    remove: removeService,
    move: moveService
  } = useFieldArray<FormServiceValues>({
    control,
    name: `groups[${index}].services`
  });

  // FUNCTIONS
  const handleDeleteComponent = (index: number) => {
    if (selectedValue === fieldServices[index]?.id) {
      setSelectedValue(null);
    }

    removeService(index);
  };

  const handleChangeResource = (event: React.MouseEvent<HTMLElement>, newValue: any) => {
    if (newValue !== null) {
      setSelectedValue(newValue);
    }
  };

  const handleSelectComponent = (
    event: ChangeEvent<{}>,
    value: ResourceData | MultiFlow | ExternalIntegration | null
  ) => {
    if (!value) return;

    switch (value.__typename) {
      case 'ExternalIntegration':
        appendService({
          name: value.name,
          displayName: '',
          useResource: undefined,
          resourceUid: undefined,
          displayEnabled: true,
          monitoringId: value.id,
          syntheticUid: undefined,
          type: 'external'
        });
        break;

      case 'Resource':
        appendService({
          name: value.name,
          displayName: '',
          useResource: true,
          resourceUid: value.uid,
          displayEnabled: true,
          monitoringId: value.id,
          syntheticUid: value.uid,
          type: 'resource'
        });
        break;

      case 'MultiFlowOutput':
        appendService({
          name: value.name,
          displayName: '',
          useResource: undefined,
          resourceUid: undefined,
          displayEnabled: true,
          monitoringId: value.id,
          type: 'synthetic'
        });
        break;
    }
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (over && active.id !== over.id) {
      const oldIndex = fieldServices.findIndex(item => item.id === active.id);
      const newIndex = fieldServices.findIndex(item => item.id === over.id);

      moveService(oldIndex, newIndex);
    }
  };

  return (
    <Paper elevation={2} className={classes.group}>
      <Box display="flex" flexDirection="row" gridGap={10}>
        <Box display="flex" flexDirection="column" gridGap={10} style={{ width: '50%' }}>
          <Input name={`groups[${index}].name`} inputRef={register} label="Group name" />

          <Autocomplete
            style={{ width: '100%' }}
            options={[
              ...(externalList?.map(item => ({
                ...item,
                group: 'EXTERNAL SERVICES MONITORING'
              })) || []),
              ...(resourcesData?.getResourcesFromIndexer.data.map(item => ({
                ...item,
                group: 'AGENT MONITORING'
              })) || []),
              ...(syntheticsData?.multiflows.data.map(item => ({
                ...item,
                group: 'SYNTHETIC MONITORING'
              })) || [])
            ]}
            getOptionLabel={option => option.name}
            groupBy={option => option.group}
            value={null}
            inputValue={inputText}
            onInputChange={(event, newInputValue) => setInputText(newInputValue)}
            onChange={(event, value: any) => {
              handleSelectComponent(event, value);
              setInputText('');
            }}
            renderInput={params => (
              <TextField
                {...params}
                placeholder="Select a component"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: <Search />,
                  className: classes.searchIcon
                }}
              />
            )}
          />

          <Paper
            elevation={2}
            style={{
              height: 326,
              padding: 10,
              border: '1px solid #D0D0D0',
              overflow: 'hidden',
              overflowY: 'auto'
            }}>
            <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={handleDragEnd}>
              <SortableContext
                items={fieldServices.map(service => ({ id: service.id ?? 'fallback-id' }))}>
                <ToggleButtonGroup
                  exclusive
                  value={selectedValue}
                  onChange={handleChangeResource}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 10,
                    height: fieldServices.length === 0 ? '100%' : '',
                    alignItems: fieldServices.length === 0 ? 'center' : '',
                    justifyContent: fieldServices.length === 0 ? 'center' : ''
                  }}>
                  {fieldServices.length > 0 ? (
                    fieldServices.map((service, serviceIndex) => (
                      <Resource
                        key={service.id}
                        form={form}
                        groupIndex={index}
                        serviceIndex={serviceIndex}
                        name={service.name || ''}
                        value={service}
                        selected={selectedValue === service.id}
                        onDelete={() => handleDeleteComponent(serviceIndex)}
                      />
                    ))
                  ) : (
                    <Typography>Select a component</Typography>
                  )}
                </ToggleButtonGroup>
              </SortableContext>
            </DndContext>
          </Paper>
        </Box>

        {selectedValue !== null &&
          (() => {
            const seletedIndex = fieldServices.findIndex(service => service.id === selectedValue);

            return <Options form={form} groupIndex={index} selectIndex={seletedIndex} />;
          })()}
      </Box>

      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="end"
        style={{ marginTop: 10 }}>
        <Button
          variant="text"
          style={{ width: 'auto', height: 'auto', color: disabled ? '' : '#E96161' }}
          disabled={disabled}
          onClick={onDelete}>
          Delete group
        </Button>
      </Box>
    </Paper>
  );
}
