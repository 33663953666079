import { PropsWithChildren } from 'react';

import { Box, Container, useTheme } from '@material-ui/core';

import { withThemeV5 } from 'theme/v5';

function MinimalLayout({ children }: PropsWithChildren<{}>) {
  const theme = useTheme();

  return (
    <Box padding={theme.spacing(6, 10)} width="100%">
      <Container maxWidth="xl" disableGutters>
        <>{children}</>
      </Container>
    </Box>
  );
}

export default withThemeV5(MinimalLayout);
