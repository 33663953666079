import { IncidentState } from 'types/external/IncidentState';
import { useQuery } from 'urql';

const GetIncidentsQuery = `#graphql
  query (
    $from: Int!
    $size: Int!
    $query: String!
    $termKey: String
    $termValue: [String!]
    $betweenKey: String
    $betweenValues: String
  ) {
    incidentCenter(
      from: $from
      size: $size
      query: $query
      termKey: $termKey
      termValue: $termValue
      betweenKey: $betweenKey
      betweenValues: $betweenValues
    ) {
      data {
        id
        title
        description
        severity
        status
        type
        createdAt
        updatedAt
        failureHappenedAt
        tags {
          name
          value
        }
        origins {
          entity
          uid
        }
        responders {
          type
          uid
        }
      }
      total
    }
  }
`;

type Variables = {
  from: number;
  size: number;
  query?: string;
  termKey?: string;
  termValue?: string[];
  betweenKey?: string;
  betweenValues?: string;
};

export const useIncidentState = ({
  page,
  perPage,
  query,
  termKey,
  termValue,
  betweenKey,
  betweenValues,
  pause
}: {
  page: number;
  perPage: number;
  query?: string;
  termKey?: string;
  termValue?: string[];
  betweenKey?: string;
  betweenValues?: string;
  pause: boolean;
}) => {
  const from = Math.max(page - 1, 0) * perPage;

  const [{ fetching, data }, reexecuteQuery] = useQuery<IncidentState, Variables>({
    query: GetIncidentsQuery,
    pause,
    variables: {
      from,
      size: perPage,
      query,
      termKey,
      termValue,
      betweenKey,
      betweenValues
    }
  });

  const incidents = data?.incidentCenter?.data.map(incident => {
    return {
      ...incident,
      incidentId: incident?.id,
      happenedAt: incident?.failureHappenedAt
    };
  });

  return {
    fetching,
    data: { incidents: incidents || [], total: data?.incidentCenter?.total || 0 },
    reexecuteQuery
  };
};
