import { makeStyles } from '@material-ui/core';

import { Theme } from 'theme/v5';

export const useStyles = makeStyles((theme: Theme) => ({
  ellipse: {
    width: '4px',
    height: '4px',
    backgroundColor: theme.palette.gray[700],
    borderRadius: '50%',
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1)
  },
  incidentTagsTitle: {
    color: theme.palette.gray[900],
    fontWeight: theme.typography.fontWeightMedium
  },
  tags: {
    display: 'flex',
    gap: 10
  },
  tagName: (props: { maxWidth: number }) => ({
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: props.maxWidth
  }),
  buttonTooltip: {
    marginLeft: 8,
    padding: 3,
    '&:disabled': {
      color: theme.palette.text.primary
    }
  },
  buttonWrapper: {
    '&:hover $buttonTooltip': {
      backgroundColor: theme.palette.action.hover
    }
  },
  tooltip: {
    whiteSpace: 'nowrap',
    maxWidth: 'none'
  }
}));
