import { Box } from '@material-ui/core';
import { UseFormMethods } from 'react-hook-form';

import { ComponentsGroup } from 'views/ServicesHub/components/ComponentsGroup';
import { FormValues } from 'views/ServicesHub/layouts/StatusPage';

export type ComponentsSettingsFormProps = {
  form: UseFormMethods<FormValues>;
};

export function ComponentsSettingsForm({ form }: ComponentsSettingsFormProps) {
  return (
    <Box display="flex" gridGap="2rem" flexDirection="column">
      <ComponentsGroup form={form} />
    </Box>
  );
}
