import { useEffect } from 'react';

import {
  Checkbox,
  FormControlLabel,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography
} from '@material-ui/core';
import { UseFormMethods } from 'react-hook-form';

import { FormValues } from 'views/ServicesHub/layouts/StatusPage';

type OptionsProps = {
  groupIndex: number;
  selectIndex: number;
  form: UseFormMethods<FormValues>;
};

export function Options({ groupIndex, selectIndex, form }: OptionsProps) {
  const { watch, setValue, register } = form;

  const fieldName = `groups[${groupIndex}].services[${selectIndex}].name`;
  const fieldDisplayName = `groups[${groupIndex}].services[${selectIndex}].displayName`;
  const fieldDisplayEnabled = `groups[${groupIndex}].services[${selectIndex}].displayEnabled`;

  useEffect(() => {
    register(fieldDisplayName);
    register(fieldDisplayEnabled);
  }, [register, fieldDisplayName, fieldDisplayEnabled]);

  const name = watch(fieldName);
  const displayName: string = watch(fieldDisplayName) || '';
  const displayEnabled: boolean = watch(fieldDisplayEnabled);

  return (
    <Paper
      elevation={2}
      style={{
        width: '50%',
        padding: 15,
        border: '1px solid #D0D0D0',
        display: 'flex',
        flexDirection: 'column',
        gap: 10
      }}>
      <Typography variant="h5">{name}</Typography>

      <TextField
        name={fieldDisplayName}
        variant="outlined"
        size="small"
        fullWidth
        label="Display name"
        value={displayName}
        onChange={event => {
          setValue(fieldDisplayName, event.target.value);
        }}
      />

      <Typography variant="subtitle2">Publish</Typography>
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        defaultValue="automatic"
        name="radio-buttons-group"
        row>
        <FormControlLabel value="automatic" control={<Radio color="primary" />} label="Automatic" />
        <FormControlLabel value="manual" control={<Radio color="primary" />} label="Manual" />
      </RadioGroup>

      <Typography variant="subtitle2">More options</Typography>
      <FormControlLabel
        style={{ marginRight: 0 }}
        label="Hide"
        control={
          <Checkbox
            color="primary"
            name={fieldDisplayEnabled}
            checked={displayEnabled}
            onChange={e => setValue(fieldDisplayEnabled, e.target.checked)}
          />
        }
      />
    </Paper>
  );
}
