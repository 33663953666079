const extractGroupNameFromDroppableId = droppableId => {
  return droppableId.split('group-')[1].split('-')[0];
};

const extractServiceIdFromDraggableId = draggableId => {
  return draggableId.split('service-')[1];
};

const removeServiceFromGroup = (group, serviceIdToDelete) => {
  return {
    ...group,
    services: group.services.filter(service => String(service.id) !== String(serviceIdToDelete))
  };
};

const removeService = ({ source, draggableId }) => statusPageComponents => {
  const groupNameFromDroppableId = extractGroupNameFromDroppableId(source.droppableId);
  const serviceIdToDelete = extractServiceIdFromDraggableId(draggableId);

  return statusPageComponents.map(component => {
    if (component.name === groupNameFromDroppableId) {
      return removeServiceFromGroup(component, serviceIdToDelete);
    }
    return component;
  });
};

export default removeService;
