import { useEffect, useState, ChangeEvent, useMemo } from 'react';

import { Grid, Button, Badge, Box } from '@material-ui/core';
import { ArrowDropDown } from '@material-ui/icons';
import dayjs from 'dayjs';
import { useHistory } from 'react-router';

import { FilterToolbar } from 'components/FilterToolbar';
import { Filter } from 'components/FilterToolbar/Filter';
import { MultipleOptionsFilter } from 'components/FilterToolbar/MultipleOptionsFilter';
import { CustomRange } from 'componentsV4/Filters/CustomRange';
import { Period } from 'componentsV4/Filters/Period';
import { isEqual } from 'helpers/compareFilters';
import useQueryParams from 'helpers/useQueryParams';
import { useServiceMonitors } from 'hooks/queriesGraphQL/useServiceMonitors';

import { SeverityEnumFilter as SeverityEnum, StatusEnumFilter as StatusEnum } from '../index';

import { useStyles } from './styles';

type IncidentFilterProps = {
  setSearch: (search: string) => void;
  setPage: (page: number) => void;
  statusFilter: StatusEnum;
  setStatusFilter: (status: StatusEnum) => void;
  severityFilter: SeverityEnum;
  setSeverityFilter: (severity: SeverityEnum) => void;
  originsFilter: string[];
  setOriginsFilter: (origins: string[]) => void;
  identifiersFilter: string[];
  identifierOptions: { value: string; label: string }[];
  setIdentifierFilter: (identifier: string[]) => void;
  respondersFilter: string[];
  setRespondersFilter: (responders: string[]) => void;
  betweenValuesFilter: string;
  setBetweenValuesFilter: (betweenValues: string) => void;
  respondersData: {
    getRespondersByOrgUid: { data: { uid: string; name: string; type: string }[] };
  };
  setTagNameFilter?: (tagNames: string[]) => void;
  setTagValueFilter?: (tagvalues: string[]) => void;
  originsQuery?: string;
};

export const Filters = ({
  setSearch,
  setPage,
  statusFilter,
  setStatusFilter,
  severityFilter,
  setSeverityFilter,
  originsFilter,
  setOriginsFilter,
  originsQuery,
  identifiersFilter,
  identifierOptions,
  setIdentifierFilter,
  respondersFilter,
  setRespondersFilter,
  betweenValuesFilter,
  setBetweenValuesFilter,
  respondersData,
  setTagNameFilter,
  setTagValueFilter
}: IncidentFilterProps) => {
  const classes = useStyles();

  const queryParams = useQueryParams();
  const history = useHistory();

  const [badgeContent, setBadgeContent] = useState<number>(0);

  const [origins, setOrigins] = useState<{ value: string; label: string }[]>([]);
  const [identifiers, setIdentifiers] = useState<{ value: string; label: string }[]>([]);
  const [responders, setResponders] = useState<{ value: string; label: string; type?: string }[]>(
    []
  );

  const [isPeriodOpen, setIsPeriodOpen] = useState(false);
  const [periodAnchorEl, setPeriodAnchorEl] = useState<null | HTMLElement>(null);
  const [badgeContentPeriod, setBadgeContentPeriod] = useState<string | number>(0);

  const [isCustomRangeOpen, setIsCustomRangeOpen] = useState(false);
  const [customRangeAnchorEl, setCustomRangeAnchorEl] = useState<null | HTMLElement>(null);
  const [startDate, setStartDate] = useState(dayjs(new Date()).format('YYYY-MM-DDTHH:mm'));
  const [endDate, setEndDate] = useState(dayjs(new Date()).format('YYYY-MM-DDTHH:mm'));
  const [badgeContentCustomRange, setBadgeContentCustomRange] = useState<string | number>(0);

  const respondersList = useMemo(() => {
    return respondersData?.getRespondersByOrgUid?.data || [];
  }, [respondersData]);

  const responderListFormated = respondersList.map(({ uid, name, type }) => ({
    value: uid,
    label: name,
    type
  }));

  const [{ data }] = useServiceMonitors({
    query: originsQuery?.length
      ? originsQuery
      : '(type: integration) OR (type: multiflow) OR (type: resource)'
  });

  const monitorsService = useMemo(() => {
    return data?.getMonitorsService?.data || [];
  }, [data]);

  const monitorsServiceFormated = monitorsService.map(({ id, uid, name, type }) => ({
    value: type === 'service' ? String(id) : String(uid),
    label: name
  }));

  const addQueryParams = (key: string, value: string) => {
    queryParams.set(key, value);
    history.replace({
      search: queryParams.toString()
    });
  };

  const removeQueryParams = (key: string) => {
    queryParams.delete(key);
    history.replace({
      search: queryParams.toString()
    });
  };

  const handleClearFilters = () => {
    removeQueryParams('status');
    removeQueryParams('severity');
    removeQueryParams('origins');
    removeQueryParams('ids');
    removeQueryParams('responders');
    removeQueryParams('tagName');
    removeQueryParams('tagValue');

    setStatusFilter(StatusEnum.All);
    setSeverityFilter(SeverityEnum.All);
    setOrigins([]);
    setOriginsFilter([]);
    setIdentifiers([]);
    setIdentifierFilter([]);
    setResponders([]);
    setRespondersFilter([]);
    setTagNameFilter && setTagNameFilter([]);
    setTagValueFilter && setTagValueFilter([]);

    setPage(1);
  };

  const handleChangeStatusFilter = (event?: ChangeEvent<HTMLInputElement>) => {
    setPage(1);
    setStatusFilter(event?.target.value as StatusEnum);

    if (event?.target.value === StatusEnum.All) {
      return removeQueryParams('status');
    }

    addQueryParams('status', event?.target.value as string);
  };

  const handleChangeSeverityFilter = (event?: ChangeEvent<HTMLInputElement>) => {
    setPage(1);
    setSeverityFilter(event?.target.value as SeverityEnum);

    if (event?.target.value === SeverityEnum.All) {
      return removeQueryParams('severity');
    }

    addQueryParams('severity', event?.target.value as string);
  };

  const handleApplyOriginFilter = (options: { value: string; label: string }[]) => {
    setPage(1);
    setOriginsFilter(options.map(options => options.value));

    addQueryParams('origins', options.map(({ value }) => value).join(','));
  };

  const handleApplyIdentifierFilter = (options: { value: string; label: string }[]) => {
    setPage(1);
    setIdentifierFilter(options.map(options => options.value));

    addQueryParams('ids', options.map(({ value }) => value).join(','));
  };

  const handleApplyRespondersFilter = (
    options: { value: string; label: string; type?: string }[]
  ) => {
    setPage(1);
    setRespondersFilter(options.map(options => options.value));

    addQueryParams('responders', options.map(({ value }) => value).join(','));
  };

  const handleTogglePeriods = (event: React.MouseEvent<HTMLElement>) => {
    setPeriodAnchorEl(event.currentTarget);
    setIsPeriodOpen(isPeriodOpen => !isPeriodOpen);
  };

  const handleApplyPeriod = ({ period }: { period: string }) => {
    setPage(1);

    if (period === 'all') {
      removeQueryParams('period');
      setBetweenValuesFilter(`now-1y,now`);
      setBadgeContentPeriod(0);
      setBadgeContentCustomRange(0);
      return;
    }

    addQueryParams('period', period);
    setBetweenValuesFilter(`now-${period},now`);
    setBadgeContentPeriod('1');
    setBadgeContentCustomRange(0);
  };

  const handleToggleCustomRange = (event: React.MouseEvent<HTMLElement>) => {
    setCustomRangeAnchorEl(event.currentTarget);
    setIsCustomRangeOpen(isCustomRangeOpen => !isCustomRangeOpen);
  };

  const handleApplyCustomRange = ({ betweenValue }: { betweenValue: string }) => {
    setPage(1);
    addQueryParams('period', betweenValue);
    setBetweenValuesFilter(betweenValue);
    setBadgeContentPeriod(0);
  };

  const handleClearCustomRange = () => {
    setPage(1);
    removeQueryParams('period');
    setBetweenValuesFilter('now-1y,now');
    setBadgeContentCustomRange(0);
  };

  useEffect(() => {
    const defaultValues = {
      status: StatusEnum.All,
      severity: SeverityEnum.All,
      origins: [],
      identifiers: [],
      responders: []
    };

    const values = {
      status: statusFilter,
      severity: severityFilter,
      origins,
      identifiers,
      responders
    };

    const changedFields = Object.keys(values).filter(key => {
      if (
        values[key as keyof typeof values] === undefined ||
        defaultValues[key as keyof typeof defaultValues] === undefined
      ) {
        return false;
      }

      return !isEqual((values as any)[key], (defaultValues as any)[key]);
    });

    const content = Math.min(changedFields.length);

    setBadgeContent(content);
  }, [statusFilter, severityFilter, origins, identifiers, responders]);

  useEffect(() => {
    if (origins && !origins?.length && originsFilter && originsFilter.length) {
      const originsWithValue = originsFilter?.map((origin: string) => {
        return {
          value: origin,
          label: '',
          type: ''
        };
      });

      setOrigins(originsWithValue);
    }
  }, [origins, setOrigins, originsFilter]);

  useEffect(() => {
    if (responders && !responders?.length && respondersFilter && respondersFilter.length) {
      const respondersWithValue = respondersFilter?.map((origin: string) => {
        return {
          value: origin,
          label: '',
          type: ''
        };
      });

      setResponders(respondersWithValue);
    }
  }, [responders, setResponders, respondersFilter]);

  useEffect(() => {
    if (identifiers && !identifiers?.length && identifiersFilter && identifiersFilter.length) {
      const identifiersWithValue = identifiersFilter?.map((origin: string) => {
        return {
          value: origin,
          label: '',
          type: ''
        };
      });

      setIdentifiers(identifiersWithValue);
    }
  }, [identifiers, setIdentifiers, identifiersFilter]);

  const periodValues = ['now-1d,now', 'now-7d,now', 'now-30d,now'];

  useEffect(() => {
    if (betweenValuesFilter === 'now-1y,now') return;

    if (betweenValuesFilter && periodValues.includes(betweenValuesFilter)) {
      setBadgeContentPeriod('1');
      setBadgeContentCustomRange(0);

      return;
    }

    if (betweenValuesFilter && !periodValues.includes(betweenValuesFilter)) {
      const date = betweenValuesFilter.split(',');

      setStartDate(dayjs(new Date(date[0])).format('YYYY-MM-DDTHH:mm'));
      setEndDate(dayjs(new Date(date[1])).format('YYYY-MM-DDTHH:mm'));

      setBadgeContentCustomRange('1');
      setBadgeContentPeriod(0);

      return;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [betweenValuesFilter]);

  return (
    <FilterToolbar
      placeholder="Search by title or cause"
      handleClearFilters={handleClearFilters}
      badgeContent={badgeContent}
      setSearch={setSearch}
      additionalFilters={
        <>
          {isPeriodOpen && (
            <Period
              anchorEl={periodAnchorEl}
              onApplyPeriod={handleApplyPeriod}
              handleClose={() => setIsPeriodOpen(false)}
            />
          )}
          {isCustomRangeOpen && (
            <CustomRange
              anchorEl={customRangeAnchorEl}
              onApplyCustomRange={handleApplyCustomRange}
              handleClose={() => setIsCustomRangeOpen(false)}
              setBadgeContentCustomRange={setBadgeContentCustomRange}
              onClearCustomRange={handleClearCustomRange}
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
            />
          )}
          <Badge
            classes={{ badge: classes.badgeContent }}
            color="primary"
            badgeContent={badgeContentPeriod}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}>
            <Button variant="outlined" onClick={handleTogglePeriods} className={classes.buttons}>
              <Box display="flex" alignItems="center" gridGap={8}>
                Period
                <ArrowDropDown />
              </Box>
            </Button>
          </Badge>
          <Badge
            classes={{ badge: classes.badgeContent }}
            color="primary"
            badgeContent={badgeContentCustomRange}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}>
            <Button
              variant="outlined"
              onClick={handleToggleCustomRange}
              className={classes.buttons}>
              <Box display="flex" alignItems="center" gridGap={8}>
                Custom Range
                <ArrowDropDown />
              </Box>
            </Button>
          </Badge>
        </>
      }>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <Filter
            name="status"
            label="Status"
            value={statusFilter}
            onChange={handleChangeStatusFilter}
            options={[
              { label: 'All', value: 'all' },
              { label: 'Alarmed', value: 'alarmed' },
              { label: 'Resolved', value: 'resolved' },
              { label: 'Acknowledged', value: 'acknowledged' }
            ]}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Filter
            name="severity"
            label="Severity Level"
            value={severityFilter}
            onChange={handleChangeSeverityFilter}
            options={[
              { label: 'All', value: 'all' },
              { label: 'Not Classified', value: 'not-classified' },
              { label: 'Critical', value: 'sev-1-critical' },
              { label: 'High', value: 'sev-2-high' },
              { label: 'Moderate', value: 'sev-3-moderate' },
              { label: 'Low', value: 'sev-4-low' },
              { label: 'Informational', value: 'sev-5-informational' }
            ]}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <MultipleOptionsFilter
            availableOptions={monitorsServiceFormated}
            selectedOptions={origins}
            onSelectedOptionsChange={setOrigins}
            onApplyFilter={handleApplyOriginFilter}
            label="Origins"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <MultipleOptionsFilter
            availableOptions={identifierOptions}
            selectedOptions={identifiers}
            onSelectedOptionsChange={setIdentifiers}
            onApplyFilter={handleApplyIdentifierFilter}
            label="Identifiers"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <MultipleOptionsFilter
            availableOptions={responderListFormated}
            selectedOptions={responders}
            onSelectedOptionsChange={setResponders}
            onApplyFilter={handleApplyRespondersFilter}
            label="Responders"
          />
        </Grid>
      </Grid>
    </FilterToolbar>
  );
};
