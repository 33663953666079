import { useMemo, useState } from 'react';

import { TextFieldProps, debounce } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { Controller, UseFormMethods } from 'react-hook-form';
import { StatusEnum } from 'types/external/Status';

import { useIncidentsState } from 'hooks/queriesGraphQL/useIncidentsState';
import { Input } from 'views/ServicesHub/components/Input';

type SelectProps = {
  control: UseFormMethods['control'];
  errors: UseFormMethods['errors'];
} & TextFieldProps;

export function IncidentsSelect({ control, errors, ...textFieldProps }: SelectProps) {
  const { name, ...inputProps } = textFieldProps;

  const [query, setQuery] = useState('');

  const handleInputChange = debounce((query: string) => {
    if (query.length === 0) return setQuery('');

    if (query.length < 3) return;

    setQuery(`(*${query}*)`);
  }, 400);

  const { data: incidents, fetching: isFetchingIncidents } = useIncidentsState({
    from: 1,
    size: 200,
    query: `(status: ${StatusEnum.Resolved})`,
    pause: !IncidentsSelect
  });

  const options = useMemo(() => {
    if (!incidents) return [];

    if (incidents.incidentsState.data.length === 0) return [];

    return incidents.incidentsState.data.map(incident => ({
      label: incident.title,
      value: incident.id
    }));
  }, [incidents]);

  return (
    <Controller
      control={control}
      name={name!}
      rules={{
        validate: value => {
          if (!value) return 'Incident is required';
        }
      }}
      render={({ value, onChange }) => {
        return (
          <Autocomplete
            freeSolo
            onChange={(_, value) => onChange(value?.value)}
            onInputChange={(_, value) => handleInputChange(value)}
            noOptionsText={query ? 'No incidents matched with query' : 'No data'}
            getOptionLabel={option => option.label ?? ''}
            getOptionSelected={(option, value) => option.value === value?.value}
            filterOptions={(options, { inputValue }) =>
              options.filter(option =>
                option.label.toLowerCase().includes(inputValue.toLowerCase())
              )
            }
            value={value?.value}
            options={options}
            loading={isFetchingIncidents}
            style={{ width: 660 }}
            loadingText="Fetching incidents..."
            size="small"
            ChipProps={{
              color: 'secondary'
            }}
            renderInput={params => (
              <>
                <Input
                  {...inputProps}
                  {...params}
                  name={name}
                  errors={errors}
                  placeholder={
                    isFetchingIncidents ? 'Fetching incidents...' : inputProps.placeholder
                  }
                  InputProps={{ ...params.InputProps, required: false }}
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </>
            )}
          />
        );
      }}
    />
  );
}
