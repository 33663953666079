import { z } from 'zod';

const FormCoverSchema = z.object({
  file: z.string().min(1, 'File is required'),
  fileName: z.string().min(1, 'File name is required'),
  contentType: z.string().min(1, 'Content type is required')
});

const FormServiceSchema = z.object({
  name: z.string().min(1, 'Service name is required'),
  displayName: z.string().optional(),
  useResource: z.boolean().optional(),
  resourceUid: z.string().optional(),
  displayEnabled: z.boolean(),
  monitoringId: z.number(),
  syntheticUid: z.string().optional(),
  type: z.enum(['synthetic', 'external', 'resource'])
});

const FormGroupSchema = z.object({
  name: z.string().min(1, 'Group name is required'),
  services: z.array(FormServiceSchema).min(1, 'At least one service is required')
});

export const schema = z.object({
  name: z.string().min(1, 'Name is required'),
  slug: z
    .string()
    .min(1, 'Slug is required')
    .regex(/^[a-z0-9-]+$/, 'Slug must be lowercase, with hyphens and no special characters'),
  subtitle: z.string().optional(),
  passphrase: z.string().min(1, 'AccessKey is required'),
  enableSubscription: z.boolean(),

  customDomain: z.string().optional(),
  dnsRecords: z.string().optional(),

  cover: FormCoverSchema.optional(),

  backgroundColorHeader: z.string().optional(),
  textColorHeader: z.string().optional(),
  textColorPage: z.string().optional(),
  textColorTitle: z.string().optional(),

  groups: z.array(FormGroupSchema).min(1, 'At least one group is required')
});
