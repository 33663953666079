import { useEffect, useState } from 'react';

import { Box, Typography, List } from '@material-ui/core';
import { useFieldArray, UseFormMethods } from 'react-hook-form';

import Button from 'componentsV4/TextButton/Button';
import { FormValues } from 'views/ServicesHub/layouts/StatusPage';

import { Group } from './Group';
import { useStyles } from './styles';

type ComponentsGroupProps = {
  form: UseFormMethods<FormValues>;
};

export function ComponentsGroup({ form }: ComponentsGroupProps) {
  const classes = useStyles();

  const { control } = form;

  const [isGroupDeleteDisabled, setIsGroupDeleteDisabled] = useState<boolean>(true);
  const { fields: fieldGroups, append: appendGroup, remove: removeGroup } = useFieldArray({
    control,
    name: 'groups'
  });

  const handleAddGroup = () => {
    appendGroup({ name: '', services: [] });
  };

  const handleDeleteGroup = (index: number) => {
    if (fieldGroups.length === 1) return;

    removeGroup(index);
  };

  useEffect(() => {
    setIsGroupDeleteDisabled(fieldGroups.length <= 1);
  }, [fieldGroups]);

  return (
    <>
      <Box className={classes.groupList}>
        <Box className={classes.groupListInfo}></Box>
        <List
          className={classes.groupSpace}
          style={fieldGroups.length > 0 ? {} : { border: '1px dotted #D5D5D5' }}>
          {fieldGroups.length > 0 ? (
            fieldGroups.map((group, index) => (
              <Group
                key={group.id || index}
                index={index}
                form={form}
                disabled={isGroupDeleteDisabled}
                onDelete={() => handleDeleteGroup(index)}
              />
            ))
          ) : (
            <Typography variant="subtitle1" style={{ padding: 16 }}>
              You have no groups or components created
            </Typography>
          )}
        </List>
      </Box>

      <Box className={classes.groupListButtons}>
        <Button
          variant="text"
          color="primary"
          style={{ width: 'auto', height: 'auto' }}
          onClick={handleAddGroup}>
          + New group
        </Button>
      </Box>
    </>
  );
}
