import {
  Breadcrumbs,
  Grid,
  Link as MuiLink,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import { ChevronRight } from '@material-ui/icons';
import clsx from 'clsx';
import { Link } from 'react-router-dom';

import useStyles from './styles';

type BreadcrumbProps = {
  items: {
    label: string;
    link?: string;
  }[];
  custom?: {
    isLastItemGray?: boolean;
  };
};

function Breadcrumb({ items, custom }: BreadcrumbProps) {
  const theme = useTheme();

  const isDesktop = useMediaQuery(theme.breakpoints.up('xl'), {
    defaultMatches: true
  });

  const classes = useStyles({ maxWidth: isDesktop ? 1000 : 500 });

  return (
    <Grid item xs={12} style={{ margin: theme.spacing(0, 2, 3, 0) }}>
      <Breadcrumbs
        separator={<ChevronRight />}
        aria-label="breadcrumb"
        className={classes.root}
        classes={{ li: classes.listItem, ol: classes.groupItem, separator: classes.separator }}>
        {items.map((item, index) => {
          const key = `${item.link}_${item.label}`;

          const isLastItem = index === items.length - 1;

          if (isLastItem) {
            return (
              <Typography
                key={key}
                color="primary"
                className={clsx(
                  classes.item,
                  classes.lastItem,
                  custom?.isLastItemGray && classes.itemGray
                )}>
                {item.label}
              </Typography>
            );
          }

          if (!item.label) {
            return null;
          }

          if (item.link) {
            return (
              <MuiLink
                key={key}
                className={clsx(classes.breadcrumbBefore, classes.item)}
                to={item.link}
                component={Link}>
                {item.label}
              </MuiLink>
            );
          }

          return (
            <Typography key={key} className={clsx(classes.breadcrumbBefore, classes.item)}>
              {item.label}
            </Typography>
          );
        })}
      </Breadcrumbs>
    </Grid>
  );
}

export default Breadcrumb;
