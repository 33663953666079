import { ChangeEvent, useState } from 'react';

import { Box, Slider } from '@material-ui/core';
import { ImageOutlined } from '@material-ui/icons';
import { useDispatch } from 'react-redux';

import Dialog from 'components/Dialog';
import actions from 'redux/actions';

import { useStyles } from './style';

type ImageAdjustDialogProps = {
  title: string;
  open: boolean;
  onClose: () => void;
  onUpdate: (resizedImageBase64: string) => void;
  imageBase64: string | null;
};

export function ImageAdjustDialog({
  title,
  open,
  onClose,
  onUpdate,
  imageBase64
}: ImageAdjustDialogProps) {
  const classes = useStyles();

  const dispatch = useDispatch();

  const [imageSize, setImageSize] = useState<number>(50);

  const handleChange = (_event: ChangeEvent<{}>, newValue: number | number[]) => {
    setImageSize(newValue as number);
  };

  const resizeImageSync = (base64: string, scale: number): string | null => {
    const img = new Image();
    img.src = base64;

    if (img.complete) {
      const canvas = document.createElement('canvas');
      canvas.width = img.width * scale;
      canvas.height = img.height * scale;
      const ctx = canvas.getContext('2d');

      if (ctx) {
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
        return canvas.toDataURL();
      }
    }

    return null;
  };

  const handleUpdate = () => {
    if (!imageBase64) {
      return;
    }

    const resizedImage = resizeImageSync(imageBase64, (50 + imageSize) / 100);

    if (resizedImage) {
      onUpdate(resizedImage);
    } else {
      dispatch({
        type: actions.ENTITY_ERROR,
        payload: { message: 'Error updating image' }
      });
      // eslint-disable-next-line no-console
      console.error('Error updating image');
    }

    onClose();
  };

  return (
    <Dialog
      title={title}
      open={open}
      showCloseButton
      onClose={onClose}
      actions={[
        {
          label: 'CANCEL',
          variant: 'outlined',
          onClick: onClose,
          color: 'default'
        },
        {
          label: 'UPDATE',
          variant: 'contained',
          type: 'submit',
          onClick: handleUpdate
        }
      ]}>
      <Box className={classes.dragAndDropBox} style={{ cursor: 'pointer' }}>
        <img
          src={imageBase64 ? imageBase64 : ''}
          alt="Image_uploaded"
          className={classes.image}
          style={{ height: `${50 + imageSize}%`, width: `${50 + imageSize}%` }}
        />

        <Box className={classes.track}>
          <ImageOutlined />
          <Slider
            aria-label="Size"
            value={imageSize}
            onChange={(event, value) => handleChange(event, value)}
          />
          <ImageOutlined />
        </Box>
      </Box>
    </Dialog>
  );
}
