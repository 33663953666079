import { ChangeEvent, useState } from 'react';

import { Box, TextField } from '@material-ui/core';
import { UseFormMethods } from 'react-hook-form';

import useFeatureFlagUnleash from 'hooks/useFeatureFlagUnleash';
import { UploadImage } from 'views/ServicesHub/components/UploadImage';
import { FormValues } from 'views/ServicesHub/layouts/StatusPage';

export type BrandCustomizationFormProps = {
  form: UseFormMethods<FormValues>;
};

const DEFAULT_MESSAGE = '#286A6B';

export function BrandCustomizationForm({ form }: BrandCustomizationFormProps) {
  const { register } = form;

  const [backgroundColor, setBackgroundColor] = useState<string>(DEFAULT_MESSAGE);
  const [textColor, setTextColor] = useState<string>(DEFAULT_MESSAGE);
  const [textColorPage, setTextColorPage] = useState<string>(DEFAULT_MESSAGE);
  const [textColorTitle, setTextColorTitle] = useState<string>(DEFAULT_MESSAGE);

  const useStatusPageColor = useFeatureFlagUnleash('useStatusPageColors', { queryString: true });

  const onChangeBackgroundColor = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setBackgroundColor(event.target.value);
  };

  const onChangeTextColor = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setTextColor(event.target.value);
  };

  const onChangeTextColorPage = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setTextColorPage(event.target.value);
  };

  const onChangeTextColorTitle = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setTextColorTitle(event.target.value);
  };

  return (
    <Box display="flex" gridGap="2rem" flexDirection="column">
      <UploadImage name="cover" title="Update banner your organization" register={register} />

      {useStatusPageColor && (
        <>
          <TextField
            fullWidth
            disabled={false}
            InputLabelProps={{
              shrink: true
            }}
            type="color"
            label="Select your background color header"
            name="backgroundColorHeader"
            margin="dense"
            onChange={onChangeBackgroundColor}
            value={backgroundColor}
            variant="outlined"
            inputRef={register}
          />

          <TextField
            fullWidth
            disabled={false}
            InputLabelProps={{
              shrink: true
            }}
            type="color"
            label="Select your text color header"
            name="textColorHeader"
            margin="dense"
            onChange={onChangeTextColor}
            value={textColor}
            variant="outlined"
            inputRef={register}
          />

          <TextField
            fullWidth
            disabled={false}
            InputLabelProps={{
              shrink: true
            }}
            type="color"
            label="Select your text color page"
            name="textColorPage"
            margin="dense"
            onChange={onChangeTextColorPage}
            value={textColorPage}
            variant="outlined"
            inputRef={register}
          />

          <TextField
            fullWidth
            disabled={false}
            InputLabelProps={{
              shrink: true
            }}
            type="color"
            label="Select your text color title"
            name="textColorTitle"
            margin="dense"
            onChange={onChangeTextColorTitle}
            value={textColorTitle}
            variant="outlined"
            inputRef={register}
          />
        </>
      )}
    </Box>
  );
}
