import { Box, Tooltip, Typography } from '@material-ui/core';
import { HelpOutlineOutlined } from '@material-ui/icons';

import { FormProps } from 'views/ServicesHub/forms';
import { CustomDomainSettingsForm } from 'views/ServicesHub/forms/statusPage/CustomDomainSettingsForm';

import { useStyles } from './styles';

type CustomDomainSettingsProps = {
  form: FormProps['form'];
};

export function CustomDomainSettings({ form }: CustomDomainSettingsProps) {
  const classes = useStyles();

  return (
    <Box>
      <Typography variant="h4" className={classes.sectionTitle}>
        Custom Domain{' '}
        <Tooltip title="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and Lorem Ipsum has been the industry's">
          <HelpOutlineOutlined style={{ color: '#5E5E5E' }} />
        </Tooltip>
      </Typography>
      <Box display="flex" gridGap="3rem">
        <Box flex={2}>
          <CustomDomainSettingsForm form={form} />
        </Box>
      </Box>
    </Box>
  );
}
