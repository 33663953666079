import { useEffect } from 'react';

import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Box, Button, IconButton, Typography } from '@material-ui/core';
import { Close, DragIndicator } from '@material-ui/icons';
import { ToggleButton } from '@material-ui/lab';
import { ArrayField, UseFormMethods } from 'react-hook-form';

import { FormServiceValues, FormValues } from 'views/ServicesHub/layouts/StatusPage';

import { useStyles } from './styles';

type ResourceProps = {
  form: UseFormMethods<FormValues>;
  name: string;
  groupIndex: number;
  serviceIndex: number;
  value?: Partial<ArrayField<FormServiceValues, 'id'>>;
  selected?: boolean;
  onDelete?: (value: any) => void;
  onChange?: () => void;
};

export function Resource({
  form,
  groupIndex,
  serviceIndex,
  name,
  value,
  selected,
  onDelete,
  onChange
}: ResourceProps) {
  const classes = useStyles();

  const { register, setValue } = form;

  useEffect(() => {
    if (!value) return;

    const basePath = `groups[${groupIndex}].services[${serviceIndex}]`;

    Object.entries(value).forEach(([key, value]) => {
      const fieldPath = `${basePath}.${key}`;
      register(fieldPath);
      setValue(fieldPath, value);
    });
  }, [register, setValue, groupIndex, serviceIndex, value]);

  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: value?.id || serviceIndex
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  };

  return (
    <ToggleButton
      ref={setNodeRef}
      style={style}
      value={value?.id || serviceIndex}
      selected={selected}
      onChange={onChange}
      className={classes.resource}>
      <Box display={'flex'} flexDirection={'row'} alignItems={'center'} style={{ gap: 10 }}>
        <Button
          size="small"
          {...attributes}
          {...listeners}
          className={classes.dragButton}
          style={{ cursor: isDragging ? 'grabbing' : 'grab' }}
          onMouseDown={e => e.stopPropagation()}>
          <DragIndicator style={{ color: selected ? 'white' : '' }} />
        </Button>

        <Typography
          variant="body1"
          style={{ textTransform: 'none', color: !selected ? '#515151' : '' }}>
          {name}
        </Typography>
      </Box>

      <IconButton
        size="small"
        onMouseDown={e => e.stopPropagation()}
        onClick={e => {
          e.preventDefault();
          if (onDelete) onDelete(value?.id || serviceIndex);
        }}>
        <Close style={{ color: selected ? '#FFF' : '' }} />
      </IconButton>
    </ToggleButton>
  );
}
