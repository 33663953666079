import { Tooltip, Box, Typography, useMediaQuery, Button } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

import { useStyles } from './styles';

const RESPONDER_MAX_VIEW = 1;

export type Responder = {
  uid: string;
  type: 'teams' | 'users';
  name: string;
};

export const ResponderTooltip = ({
  responders,
  ellipseStyles,
  textVariant,
  buttonStyles
}: {
  responders: Responder[];
  ellipseStyles?: Record<string, string | number>;
  textVariant?: any;
  buttonStyles?: Record<string, string | number>;
}) => {
  const theme = useTheme();

  const isDesktop = useMediaQuery(theme.breakpoints.up('xl'), {
    defaultMatches: true
  });

  const classes = useStyles({ maxWidth: isDesktop ? 220 : 110 });

  const formatResponder = (type: string) => {
    if (type === 'users') return 'User';

    return 'Team';
  };

  const renderResponderInfo = ({
    limitCharacters = true,
    responder
  }: {
    limitCharacters?: boolean;
    responder: Responder;
  }) => {
    const responderNameCharacters = isDesktop ? 30 : 16;

    return (
      <Box className={classes.responderBox}>
        <Typography
          variant={textVariant ? textVariant : 'body2'}
          className={classes.responderLabel}>
          {formatResponder(responder?.type || '')}:
        </Typography>
        <Tooltip
          title={
            (responder?.name && responder?.name?.length > responderNameCharacters
              ? responder?.name
              : '') || ''
          }
          placement="top">
          <Typography
            variant={textVariant ? textVariant : 'body2'}
            className={limitCharacters ? classes.responderName : undefined}>
            {responder?.name}
          </Typography>
        </Tooltip>
      </Box>
    );
  };

  const RespondersList = ({ responders }: { responders: Responder[] }) => (
    <Box className={classes.responders}>
      {responders.map(responder => renderResponderInfo({ responder }))}
    </Box>
  );

  const RespondersPopover = ({ responders }: { responders: Responder[] }) => (
    <Tooltip
      title={
        <Box>
          {responders.map(responder => renderResponderInfo({ limitCharacters: false, responder }))}
        </Box>
      }
      placement="bottom">
      <span className={classes.buttonWrapper}>
        <Button disabled={true} className={classes.buttonTooltip} style={buttonStyles}>
          See more
        </Button>
      </span>
    </Tooltip>
  );

  return (
    <>
      <span style={ellipseStyles} className={`${classes.ellipse} ${classes.firstChild} `} />
      <Typography
        variant={textVariant ? textVariant : 'body2'}
        className={classes.incidentDateTitle}>
        Responders: &nbsp;
      </Typography>
      <RespondersList responders={responders.slice(0, RESPONDER_MAX_VIEW)} />
      {responders.length > RESPONDER_MAX_VIEW && (
        <RespondersPopover responders={responders.slice(RESPONDER_MAX_VIEW)} />
      )}
    </>
  );
};
