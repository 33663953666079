import MainLayoutV5 from 'layouts/v5/Main';
import MinimalLayoutV5 from 'layouts/v5/Minimal';
import { Settings as SettingsView, Terms as TermsView, Loading as LoadingView } from 'views';
import { ForbiddenView } from 'views/Forbidden';
import { LoginErrorView } from 'views/LoginError';
import { NotFoundView } from 'views/NotFound';
import SlackIntegrationDone from 'views/SlackIntegrationDone';

import RouteWithLayoutV2 from '../components/RouteWithLayoutV2';

export const utilityRoutesWithLayoutV2 = [
  <RouteWithLayoutV2 component={SettingsView} exact layout={MainLayoutV5} path="/settings" />,
  <RouteWithLayoutV2 component={TermsView} exact layout={MainLayoutV5} path="/terms" />,
  <RouteWithLayoutV2
    component={TermsView}
    exact
    layout={MinimalLayoutV5}
    path="/public-terms"
    isPublicRoute={true}
  />,
  <RouteWithLayoutV2 component={LoadingView} exact layout={MainLayoutV5} path="/loading" />,
  <RouteWithLayoutV2 component={NotFoundView} exact layout={MainLayoutV5} path="/not-found" />,
  <RouteWithLayoutV2 component={ForbiddenView} exact layout={MainLayoutV5} path="/forbidden" />,
  <RouteWithLayoutV2
    component={SlackIntegrationDone}
    exact
    layout={MainLayoutV5}
    path="/slack-integration-done"
    isPublicRoute={true}
  />,
  <RouteWithLayoutV2
    component={LoginErrorView}
    exact
    layout={MainLayoutV5}
    path="/login-error"
    isPublicRoute={true}
  />
];
