import { ChangeEvent, useEffect, useState } from 'react';

import { Box, FormControlLabel, Switch } from '@material-ui/core';
import { UseFormMethods } from 'react-hook-form';

import InputWithSecrets from 'componentsV3/InputWithSecrets';
import { Input } from 'views/ServicesHub/components/Input';
import { FormValues } from 'views/ServicesHub/layouts/StatusPage';

export type StatusPageFormProps = {
  form: UseFormMethods<FormValues>;
};

export function StatusPageForm({ form }: StatusPageFormProps) {
  const { control, watch, errors, setValue, register } = form;

  const [isChecked, setIsChecked] = useState(true);

  const nameValue = watch('name');

  useEffect(() => {
    if (nameValue) {
      const slug = nameValue
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase()
        .replace(/\s+/g, '-')
        .replace(/[^a-z0-9-]/g, '');

      setValue('slug', slug, { shouldValidate: true, shouldDirty: true });
    }
  }, [nameValue, setValue]);

  const handleSwitch = (event: ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    setIsChecked(checked);
    setValue('enableSubscription', checked);
  };

  return (
    <Box display="flex" gridGap="2rem" flexDirection="column">
      <Box display="flex" gridGap="2rem" flexDirection="row">
        <Input label="Name" name="name" inputRef={register} errors={form.errors} />
        <Input label="Slug" name="slug" inputRef={register} errors={form.errors} />
      </Box>

      <Input
        label="Description"
        name="subtitle"
        inputRef={register}
        minRows={4}
        multiline
        required={false}
      />

      <FormControlLabel
        label="Enable to set up automatic alert opening"
        control={
          <Switch
            name="enableSubscription"
            color="primary"
            checked={isChecked}
            inputRef={register}
            onChange={handleSwitch}
          />
        }
      />

      <InputWithSecrets
        control={control}
        watch={watch}
        errors={errors}
        setValue={setValue}
        TextFieldProps={{
          type: 'password',
          label: 'Insert access key',
          name: 'passphrase'
        }}
        InputComponent={Input}
      />
    </Box>
  );
}
