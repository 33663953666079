import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.grey[500]
  },
  item: {
    color: theme.palette.primary.main,
    fontSize: theme.typography.pxToRem(16)
  },
  breadcrumbBefore: {
    color: theme.palette.grey[500],
    whiteSpace: 'nowrap'
  },
  listItem: {
    display: 'flex',
    alignItems: 'center'
  },
  lastItem: (props: { maxWidth: number }) => ({
    width: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: props.maxWidth
  }),
  groupItem: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap'
  },
  separator: {
    color: theme.palette.action.active,
    fontSize: theme.typography.pxToRem(20)
  },
  itemGray: {
    color: theme.palette.grey[500]
  }
}));

export default useStyles;
