import { makeStyles } from '@material-ui/core';

import { Theme } from 'theme/v5';

export const useStyles = makeStyles((theme: Theme) => ({
  responders: {
    display: 'flex',
    gap: 10
  },
  buttonTooltip: {
    marginLeft: 8,
    padding: 3,
    '&:disabled': {
      color: theme.palette.text.primary
    }
  },
  responderBox: {
    display: 'flex',
    gap: 3
  },
  responderLabel: {
    fontWeight: 500
  },
  responderName: (props: { maxWidth: number }) => ({
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: props.maxWidth
  }),
  ellipse: {
    width: '4px',
    height: '4px',
    backgroundColor: theme.palette.gray[700],
    borderRadius: '50%',
    marginRight: theme.spacing(1)
  },
  firstChild: {
    marginLeft: theme.spacing(1)
  },
  incidentDateTitle: {
    color: theme.palette.gray[900],
    fontWeight: theme.typography.fontWeightMedium
  },
  buttonWrapper: {
    '&:hover $buttonTooltip': {
      backgroundColor: theme.palette.action.hover
    }
  }
}));
