import { ExternalServiceBaseForm } from 'types/external/ExternalService';

export function externalServiceAdapter(data: ExternalServiceBaseForm) {
  const responders = data?.responders?.map(responder => {
    if (responder.type) {
      return {
        uid: responder?.value,
        type: responder?.type
      };
    }

    return {
      responderId: Number(responder?.value)
    };
  });

  return {
    name: data.name,
    responders
  };
}

export function externalServiceOriginAdapter(str: string) {
  switch (str) {
    case 'mongodb':
      return 'mongodb-atlas';
    case 'azure':
      return 'azure-monitor';
    default:
      return str;
  }
}
