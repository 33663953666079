import { makeStyles } from '@material-ui/core';
import { ClassNameMap } from '@material-ui/styles';

import { Theme } from 'theme/v4';

export enum useStylesEnum {
  title = 'title',
  sectionTitle = 'sectionTitle',
  infoSectionTitle = 'infoSectionTitle',
  infoSectionDescription = 'infoSectionDescription'
}

export const useStyles: (props?: any) => ClassNameMap<useStylesEnum> = makeStyles<Theme>(
  (theme: Theme) => ({
    title: {
      fontSize: theme.typography.pxToRem(24),
      display: 'flex',
      alignItems: 'center',
      gap: 15
    },
    sectionTitle: {
      fontSize: theme.typography.pxToRem(24),
      marginBottom: theme.spacing(4),
      display: 'flex',
      alignItems: 'center',
      gap: 15
    },
    infoSectionTitle: {
      fontWeight: 'bold'
    },
    infoSectionDescription: {
      color: theme.palette.gray[500]
    }
  })
);
