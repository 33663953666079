import { Box, Tooltip, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { Tag } from 'types/external/IncidentState';

import Button from 'componentsV4/TextButton/Button';

import { useStyles } from './styles';

type TagsTooltipProps = {
  tags: Tag[];
};

const TAGS_MAX_VIEW = 1;

export const TagsTooltip = ({ tags }: TagsTooltipProps) => {
  const theme = useTheme();

  const isDesktop = useMediaQuery(theme.breakpoints.up('xl'), {
    defaultMatches: true
  });

  const classes = useStyles({ maxWidth: isDesktop ? 220 : 110 });

  const mainTags = tags.slice(0, TAGS_MAX_VIEW);
  const lastTags = tags.slice(TAGS_MAX_VIEW);

  const renderTagInfo = ({ tag }: { tag: Tag }) => {
    const tagValueCharacters = isDesktop ? 30 : 16;

    return (
      <Box display={'flex'} alignItems={'center'}>
        <Tooltip title={(tag.name.length > tagValueCharacters ? tag.name : '') || ''}>
          <Typography variant="body2" style={{ fontWeight: 500 }} className={classes.tagName}>
            {tag.name}
          </Typography>
        </Tooltip>
        :
        <Tooltip title={(tag.value.length > tagValueCharacters ? tag.value : '') || ''}>
          <Typography variant="body2" className={classes.tagName} style={{ marginLeft: 3 }}>
            {tag.value}
          </Typography>
        </Tooltip>
      </Box>
    );
  };

  return (
    <>
      <span className={classes.ellipse} />

      <Box display="flex" alignItems="center" style={{ flexWrap: 'nowrap' }}>
        <Typography variant={'body2'} className={classes.incidentTagsTitle}>
          Tags: &nbsp;
        </Typography>
        <Box className={classes.tags}>{mainTags.map(tag => renderTagInfo({ tag }))}</Box>
        {tags.length > TAGS_MAX_VIEW && (
          <Tooltip
            classes={{ tooltip: classes.tooltip }}
            title={
              <Box>
                {lastTags.map(tag => (
                  <Box display={'flex'} alignItems={'center'} style={{ gap: 3 }}>
                    <Typography variant="body2" style={{ fontWeight: 500 }}>
                      {tag.name}:
                    </Typography>
                    <Typography variant="body2">{tag.value}</Typography>
                  </Box>
                ))}
              </Box>
            }
            placement="bottom">
            <span className={classes.buttonWrapper}>
              <Button disabled={true} className={classes.buttonTooltip} style={{ height: '100%' }}>
                See more
              </Button>
            </span>
          </Tooltip>
        )}
      </Box>
    </>
  );
};
