import { Resource } from 'types/external/Resource';
import { useQuery } from 'urql';

const GetResourcesQuery = `#graphql
  query(
    $from: Int!,
    $size: Int!,
    $query: String!
  ) {
    getResourcesFromIndexer(
      from: $from,
      size: $size,
      query: $query
    ) {
      data {
        id
        uid
        name
        serviceId
        origin
        domainSettings
        status {
          status
        }        
        environment {
          name
        }
        __typename
      }
      total
    }
  }
`;

type UseResourceParams = {
  page: number;
  perPage: number;
  query: string;
  pause?: boolean;
};

export type UseResourcesData = {
  data: Resource[];
  total: number;
};

export function useResources({ page, perPage: size, query, pause = false }: UseResourceParams) {
  const from = Math.max(page - 1, 0) * size;

  return useQuery<{ getResourcesFromIndexer: UseResourcesData }>({
    query: GetResourcesQuery,
    variables: {
      from,
      size,
      query
    },
    pause
  });
}
