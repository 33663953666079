import { useMutation } from 'urql';

const CreateStatusPageMutation = `#graphql
  mutation CreateStatusPage($data: CreateStatusPageInput) { 
    createStatusPage(data: $data)
  }
`;

export function useStatusPage() {
  const [{ fetching: isCreating }, createStatusPage] = useMutation(CreateStatusPageMutation);

  return { createStatusPage, isCreating };
}
