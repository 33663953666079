import { useEffect } from 'react';

import Autocomplete from './Autocomplete';

type Option = {
  value: string;
  label: string;
  type?: string;
};

type MultipleOptionsFilterProps = {
  availableOptions: Option[];
  selectedOptions: Option[];
  onSelectedOptionsChange: (options: Option[]) => void;
  onApplyFilter: (options: Option[]) => void;
  label: string;
};

export const MultipleOptionsFilter = ({
  availableOptions,
  selectedOptions,
  onSelectedOptionsChange,
  onApplyFilter,
  label = 'Options'
}: MultipleOptionsFilterProps) => {
  const handleChangeOption = (event: Option[]) => {
    onSelectedOptionsChange(event);
    onApplyFilter(event);
  };

  const filteredAvailableOptions = availableOptions.filter(
    availableOption =>
      !selectedOptions?.find(selectedOption => availableOption.value === selectedOption.value)
  );

  useEffect(() => {
    if (
      availableOptions &&
      availableOptions.length &&
      selectedOptions &&
      selectedOptions.length &&
      selectedOptions[0]?.label === ''
    ) {
      const formatedOptions = selectedOptions.map(formatedOption => {
        const option = availableOptions.find(
          originOption => originOption.value === formatedOption?.value
        );

        return {
          label: option?.label || 'Without Name',
          value: option?.value!,
          type: option?.type!
        };
      });

      onSelectedOptionsChange(formatedOptions);
    }
  }, [availableOptions, selectedOptions, onSelectedOptionsChange]);

  return (
    <Autocomplete
      options={selectedOptions}
      handleChangeOption={handleChangeOption}
      orderedOptions={filteredAvailableOptions}
      getOptionLabel={option => option.label}
      getOptionSelected={(option, value) => option.value === value.value}
      label={label}
    />
  );
};
