import { Box, Divider, Link, Typography } from '@material-ui/core';
import { format, parseISO } from 'date-fns';
import { Link as RouterLink } from 'react-router-dom';
import { SeverityStatusEnum } from 'types/external/Severity';
import { StatusEnum } from 'types/external/Status';

import { Responder, ResponderTooltip } from 'components/ResponderTooltip';
import { reduceString } from 'helpers/reduceString';
import { StatusChip } from 'views/AlertCenter/components/StatusChip';

import { useStyles } from './styles';

export const LastEvents = ({
  id,
  severity,
  status,
  title,
  to,
  happenedAt,
  updatedAt,
  responders
}: {
  id: number;
  severity: SeverityStatusEnum;
  status: StatusEnum;
  title: string;
  to: string;
  happenedAt: string;
  updatedAt: string;
  responders?: Responder[];
}) => {
  const classes = useStyles();

  return (
    <>
      <Box key={id} className={classes.divider}>
        <Box display="flex" alignItems="center" mt={1}>
          <StatusChip type="Severity" severityStatus={severity} isAlertStatus={false} />
          <StatusChip type="Event" status={status} isAlertStatus={true} />
          <Typography variant="body1" title={title} className={classes.title}>
            {reduceString(title, 80)}
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" mb={1} mt={1}>
          <Typography variant="caption" className={classes.idTitle}>
            ID: &nbsp;
          </Typography>
          <Link variant="caption" className={classes.link} component={RouterLink} to={to}>
            #{id}
          </Link>
          <span className={`${classes.ellipse} ${classes.firstChild}`}></span>
          <Typography variant="caption" className={classes.dateTitle}>
            Started at: &nbsp;
          </Typography>
          <Typography variant="caption" className={classes.date}>
            {format(parseISO(happenedAt), "dd/MM/yyyy HH:mm 'GMT-3'")} &nbsp;
          </Typography>
          {status === StatusEnum.Resolved && (
            <>
              <span className={classes.ellipse}></span>

              <Typography variant="caption" className={classes.dateTitle}>
                Ended at: &nbsp;
              </Typography>
              <Typography variant="caption" className={classes.date}>
                {format(parseISO(updatedAt), "dd/MM/yyyy HH:mm 'GMT-3'")}
              </Typography>
            </>
          )}
          {responders?.length ? (
            <ResponderTooltip
              responders={responders}
              ellipseStyles={{ width: '5px', height: '5px' }}
              textVariant="caption"
              buttonStyles={{ fontSize: 12 }}
            />
          ) : null}
        </Box>
        <Divider className={classes.dividerContainer} />
      </Box>
    </>
  );
};
