import { GuideParams, GuideType } from 'types/external/ExternalService';

import { getEnvUrl } from 'helpers/getEnvString';

export const IncidentAzureMonitorGuide = ({
  orgUid,
  token,
  integrationUid
}: GuideParams): GuideType => {
  return {
    name: 'Azure Monitor',
    errors: ['Select an api token'],
    useToken: true,
    externalServiceUrl: `https://${getEnvUrl()}/${orgUid}/incidents/v1/azure-monitor/${integrationUid}?token=${token}`
  };
};
