import { makeStyles } from '@material-ui/core';
import { ClassNameMap } from '@material-ui/styles';

import { Theme } from 'theme/v4';

type StyleProps = {
  isDragging: boolean;
};

export enum useStylesEnum {
  inputFile = 'inputFile',
  dragAndDropBox = 'dragAndDropBox',
  image = 'image',
  track = 'track'
}

export const useStyles: (props?: any) => ClassNameMap<useStylesEnum> = makeStyles<Theme>(
  (theme: Theme) => ({
    inputFile: {
      display: 'none'
    },
    dragAndDropBox: {
      width: '100%',
      height: 240,
      borderStyle: 'dashed',
      borderColor: theme.palette.gray[500],
      backgroundColor: props =>
        (props as StyleProps).isDragging ? theme.palette.green['100'] : '',
      borderWidth: 1,
      cursor: 'pointer',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      gap: 8,
      borderRadius: 5,
      marginBottom: '1rem',
      position: 'relative',
      overflow: 'hidden'
    },
    image: {
      width: '100%',
      objectFit: 'scale-down'
    },
    track: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
      gap: 10,
      position: 'absolute',
      width: '80%',
      bottom: 20
    }
  })
);
