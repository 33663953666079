import { useQuery } from 'urql';

const GetExternalIntegration = `#graphql
  query{
    getExternalIntegrations{
      id,
       uid,
       name,
       origin,
       __typename
     }
  }
`;

type ExternalIntegration = {
  id: number;
  uid: string;
  name: string;
  origin: string;
};

const useExternalIntegration = (pause: boolean = false) => {
  const [{ data, fetching }, reexecuteQuery] = useQuery<{
    getExternalIntegrations: ExternalIntegration[];
  }>({
    query: GetExternalIntegration,
    pause: pause
  });

  const { getExternalIntegrations } = data || { getExternalIntegrations: [] };

  return { externalList: getExternalIntegrations, fetching, reexecuteQuery };
};

export default useExternalIntegration;
