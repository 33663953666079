import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { useQueryCache } from 'react-query';
import { useDispatch } from 'react-redux';
import { useMutation } from 'urql';

import getErrorMessageFromUrql from 'helpers/getErrorMessageFromUrql';
import { useFeaturePlan } from 'hooks/useFeaturePlan';
import actions from 'redux/actions';

import { Contact, Inputs } from '.';

export function useContactMethod({
  contacts,
  handleCloseDialogAction
}: {
  contacts: Contact[];
  handleCloseDialogAction: () => void;
}) {
  const queryCache = useQueryCache();
  const dispatch = useDispatch();
  const useResponderWhatsApp = useFeaturePlan('Responders.WhatsApp');

  const [{ fetching }, createUserProviders] = useMutation(
    `
      mutation($providers: [CreateUserProviderInput!]!) {
        createUserProviders(
          providers: $providers
        ) {
          id
          provider
          createdAt
        }
      }
    `
  );

  function isPhoneNumberContactMethod(method: Contact['method']) {
    return ['sms', 'voice', 'whatsapp'].includes(method);
  }

  const onSubmit = async (data: Inputs) => {
    const value = isPhoneNumberContactMethod(data.method)
      ? String(data.value).replace(/\D/g, '')
      : data.value;

    if (!value)
      return dispatch({
        type: actions.GLOBAL_WARNING,
        payload: 'Enter value to contact method'
      });

    if (data.reusePhoneNumber) {
      const providers = [
        {
          provider: 'voice',
          value
        },
        {
          provider: 'sms',
          value
        },
        {
          provider: 'whatsapp',
          value
        }
      ].filter(
        providerToCreate =>
          !contacts.some(contact => providerToCreate.provider === contact.method) &&
          (useResponderWhatsApp || providerToCreate.provider !== 'whatsapp')
      );

      const result = await createUserProviders({ providers });

      if (result.error) {
        dispatch({
          type: actions.ENTITY_ERROR,
          payload: { message: getErrorMessageFromUrql(result.error.message) }
        });

        return;
      }

      dispatch({
        type: actions.GLOBAL_SUCCESS,
        payload: 'Contact method created sucessfully'
      });

      queryCache.invalidateQueries('userProviders');
      queryCache.invalidateQueries('userProvidersSettings', {
        refetchInactive: true
      });

      return;
    }

    const result = await createUserProviders({
      providers: [
        {
          provider: data.method,
          value
        }
      ]
    });

    if (result.error) {
      dispatch({
        type: actions.ENTITY_ERROR,
        payload: { message: getErrorMessageFromUrql(result.error.message) }
      });

      return;
    }

    dispatch({
      type: actions.GLOBAL_SUCCESS,
      payload: 'Contact method created sucessfully'
    });

    queryCache.invalidateQueries('userProviders');
    queryCache.invalidateQueries('userProvidersSettings', {
      refetchInactive: true
    });

    const validatePhoneMethods = new Set(['voice', 'sms', 'whatsapp']);

    if (validatePhoneMethods.has(data.method)) {
      return;
    }

    handleCloseDialogAction();
  };

  function formatContactMethodValue(contact: Contact) {
    const { value, method } = contact;

    if (!isPhoneNumberContactMethod(method)) return value;

    try {
      const phoneNumber = parsePhoneNumberFromString(`+${value}`);

      return phoneNumber?.formatInternational() || value;
    } catch (error) {
      return value;
    }
  }

  return { onSubmit, formatContactMethodValue, isLoading: fetching };
}
