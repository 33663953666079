import { makeStyles } from '@material-ui/core';
import { ClassNameMap } from '@material-ui/styles';

import { Theme } from 'theme/v4';

export enum useStylesEnum {
  container = 'container',
  infoSectionDescription = 'infoSectionDescription',
  groupList = 'groupList',
  groupListInfo = 'groupListInfo',
  groupListButtons = 'groupListButtons',
  groupSpace = 'groupSpace',
  group = 'group',
  groupHeader = 'groupHeader',
  groupHeaderContent = 'groupHeaderContent',
  resource = 'resource',
  inputNotEditing = 'inputNotEditing',
  inputEditing = 'inputEditing',
  menuItemTitle = 'menuItemTitle',
  menuItem = 'menuItem',
  selectMenu = 'selectMenu',
  groupResources = 'groupResources',
  searchIcon = 'searchIcon',
  dragButton = 'dragButton'
}

export const useStyles: (props?: any) => ClassNameMap<useStylesEnum> = makeStyles<Theme>(
  (theme: Theme) => ({
    container: {
      backgroundColor: '#F6F6F6',
      borderRadius: 10,
      padding: 16,
      border: '1px solid #D5D5D5'
    },
    infoSectionDescription: {
      color: theme.palette.gray[500]
    },
    groupList: {},
    groupListInfo: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-end'
    },
    groupListButtons: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: 10
    },
    groupSpace: {
      backgroundColor: '#FFF',
      borderRadius: 5,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      padding: 0,
      gap: 10
    },
    groupResources: {
      backgroundColor: 'grey',
      borderRadius: 5,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      padding: 0,
      gap: 10,
      height: 100
    },
    group: {
      display: 'flex',
      backgroundColor: '#fff',
      width: '100%',
      padding: 16,
      borderRadius: 5,
      border: '1px solid #D5D5D5',
      flexDirection: 'column',
      alignItems: 'stretch'
    },
    groupHeader: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: 16,
      justifyContent: 'space-between',
      marginBottom: 10
    },
    groupHeaderContent: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',

      gap: 10,
      width: '100%'
    },
    resource: {
      display: 'flex',
      backgroundColor: '#FFF',
      width: '100%',
      padding: 10,
      flexDirection: 'row',
      alignItems: 'center',
      borderRadius: 5,
      border: '1px solid #D5D5D5',
      justifyContent: 'space-between',
      cursor: 'pointer',

      '&.Mui-selected': {
        backgroundColor: '#82CEAB',
        color: 'white',
        border: '1px solid #82CEAB',
        '&:hover': {
          backgroundColor: '#6CBF98'
        }
      }
    },
    inputNotEditing: {
      padding: 0,

      '& input': {
        padding: 0
      }
    },
    inputEditing: {
      display: 'flex',
      border: '1px solid #D5D5D5',
      borderRadius: 5,
      padding: '5px 5px',
      backgroundColor: '#fff',
      alignItems: 'start',
      width: '100%',

      '& input': {
        padding: 0
      }
    },
    menuItemTitle: {
      fontWeight: 'bold'
    },
    menuItem: {},
    selectMenu: {
      display: 'flex',
      width: '50%',

      '& div': {
        padding: '10.5px 14px'
      }
    },
    searchIcon: {},
    dragButton: {
      width: 32,
      minWidth: 32
    }
  })
);
