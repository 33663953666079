import { makeStyles } from '@material-ui/core';

import { Theme } from 'theme/v5';

export const useStyles = makeStyles((theme: Theme) => ({
  badgeContent: {
    backgroundColor: theme.palette.primary.main,
    minWidth: 16,
    height: 16
  },
  buttons: {
    minWidth: 115
  }
}));
