import { ChangeEvent, useEffect, useState } from 'react';

import { Box, Divider, Link, Typography, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';
import { format, parseISO } from 'date-fns';
import { DeleteEntity } from 'oldComponents/DeleteDialog';
import { useDispatch } from 'react-redux';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { SeverityStatusEnum } from 'types/external/Severity';
import { SourceEnum } from 'types/external/Source';
import { StatusEnum } from 'types/external/Status';
import { useMutation } from 'urql';

import Breadcrumb from 'components/Breadcrumb';
import { Responder, ResponderTooltip } from 'components/ResponderTooltip';
import { TagsTooltip } from 'components/TagsTooltip';
import { Title } from 'components/Title';
import LoadingOverlay from 'componentsV3/LoadingOverlay';
import { EventsFilters } from 'componentsV4/Filters';
import { PageFilters as NewPageFilters } from 'componentsV4/Filters/Search';
import { customElasticQuery } from 'helpers/customElasticQuery';
import { injectAuditTrailMetadata } from 'helpers/injectAuditTrailMetadata';
import { reduceString } from 'helpers/reduceString';
import useQueryParams from 'helpers/useQueryParams';
import { useIncidentState } from 'hooks/queriesGraphQL/useIncidentState';
import { useResponders } from 'hooks/queriesGraphQL/useResponders';
import useDebounce from 'hooks/useDebounce';
import useFeatureFlagUnleash from 'hooks/useFeatureFlagUnleash';
import { usePermission } from 'hooks/usePermission';
import actions from 'redux/actions';
import { StatusChip } from 'views/AlertCenter/components/StatusChip';

import CreateManualIncidentButton from './components/CreateManualIncidentButton';
import { Filters } from './components/Filters';
import IncidentMenu from './components/Menu';
import { useStyles } from './styles';
import {
  transformPeriodQuery,
  transformSeverityQuery,
  transformSourceQuery,
  transformStatusQuery
} from './transformQuery';

const TYPING_DEBOUNCE_TIME = 500; // 0.5s

const DeleteIncidentMutation = `#graphql
  mutation ($incidentId: Float!){
    deleteIncident(incidentId: $incidentId)
  }
`;

export const days = ['1y', '1d', '7d', '30d'];

export enum StatusEnumFilter {
  All = 'all',
  Alarmed = 'alarmed',
  Resolved = 'resolved',
  Acknowledged = 'acknowledged'
}

export enum SeverityEnumFilter {
  All = 'all',
  Critical = 'sev-1-critical',
  High = 'sev-2-high',
  Moderate = 'sev-3-moderate',
  Low = 'sev-4-low',
  Informational = 'sev-5-informational',
  NotClassified = 'not-classified'
}

export function IncidentCenter() {
  const theme = useTheme();

  const isDesktop = useMediaQuery(theme.breakpoints.up('xl'), {
    defaultMatches: true
  });

  const queryParams = useQueryParams();
  const classes = useStyles({ maxWidth: isDesktop ? 220 : 110 });
  const history = useHistory();
  const dispatch = useDispatch();

  const shouldUseNewApplications = useFeatureFlagUnleash('newApplications', {
    queryString: true
  });

  const shouldUseFilterWithResponders = useFeatureFlagUnleash('useIncidentFilterWithResponders', {
    queryString: true
  });

  const shouldUseTagsFilter = useFeatureFlagUnleash('useTagsFilter', {
    queryString: true
  });

  const [search, setSearch] = useState('');
  const [status, setStatus] = useState<StatusEnum | 'all'>('all');
  const [severity, setSeverity] = useState<SeverityStatusEnum | 'all'>('all');
  const [source, setSource] = useState<SourceEnum | 'all'>('all');
  const [period, setPeriod] = useState<string | 'all'>('all');
  const [page, setPage] = useState(1);
  const [betweenValues, setBetweenValues] = useState('now-1y,now');
  const [origins, setOrigins] = useState<string[]>([]);
  const [ids, setIds] = useState<string[]>([]);

  const [statusFilter, setStatusFilter] = useState<StatusEnumFilter>(StatusEnumFilter.All);
  const [severityFilter, setSeverityFilter] = useState<SeverityEnumFilter>(SeverityEnumFilter.All);
  const [originsFilter, setOriginsFilter] = useState<string[]>([]);
  const [identifierFilter, setIdentifierFilter] = useState<string[]>([]);
  const [respondersFilter, setRespondersFilter] = useState<string[]>([]);
  const [tagNameFilter, setTagNameFilter] = useState<string[]>([]);
  const [tagValueFilter, setTagValueFilter] = useState<string[]>([]);
  const [betweenValuesFilter, setBetweenValuesFilter] = useState<string>('now-1y,now');

  const sourceURL = queryParams.get('source');
  const statusURL = queryParams.get('status');
  const severityURL = queryParams.get('severity');
  const periodURL = queryParams.get('period');
  const originsURL = queryParams.get('origins');
  const idsURL = queryParams.get('ids');
  const respondersURL = queryParams.get('responders');
  const tagNameURL = queryParams.get('tagName');
  const tagValueURL = queryParams.get('tagValue');

  const addQueryParams = (key: string, value: string) => {
    queryParams.set(key, value);
    history.replace({
      search: queryParams.toString()
    });
  };

  const removeQueryParams = (key: string) => {
    queryParams.delete(key);
    history.replace({
      search: queryParams.toString()
    });
  };

  useEffect(() => {
    if (shouldUseFilterWithResponders) {
      statusURL && transformStatusQuery(statusURL, setStatusFilter);
      severityURL && transformSeverityQuery(severityURL, setSeverityFilter);
      originsURL && setOriginsFilter(originsURL.split(','));
      idsURL && setIdentifierFilter(idsURL.split(','));
      respondersURL && setRespondersFilter(respondersURL.split(','));
      tagNameURL && setTagNameFilter(tagNameURL.split(','));
      tagValueURL && setTagValueFilter(tagValueURL.split(','));

      if (periodURL?.length) {
        days.includes(periodURL)
          ? setBetweenValuesFilter(`now-${periodURL},now`)
          : setBetweenValuesFilter(periodURL ?? 'now-1y,now');

        return;
      }

      periodURL && transformPeriodQuery(periodURL, setBetweenValuesFilter);

      return;
    }

    sourceURL && transformSourceQuery(sourceURL, setSource);
    statusURL && transformStatusQuery(statusURL, setStatus);
    severityURL && transformSeverityQuery(severityURL, setSeverity);
    originsURL && setOrigins(originsURL.split(','));
    idsURL && setIds(idsURL.split(','));

    if (periodURL?.length) {
      days.includes(periodURL)
        ? setBetweenValues(`now-${periodURL},now`)
        : setBetweenValues(periodURL ?? 'now-1y,now');

      return;
    }

    periodURL && transformPeriodQuery(periodURL, setPeriod);
  }, [
    sourceURL,
    statusURL,
    severityURL,
    periodURL,
    respondersURL,
    period,
    severity,
    status,
    source,
    betweenValues,
    setBetweenValues,
    originsURL,
    setOrigins,
    idsURL,
    setIds,
    setStatusFilter,
    setSeverityFilter,
    setOriginsFilter,
    setIdentifierFilter,
    setRespondersFilter,
    setBetweenValuesFilter,
    shouldUseFilterWithResponders,
    tagNameURL,
    setTagNameFilter,
    tagValueURL,
    setTagValueFilter
  ]);

  const [incidentid] = useState<{
    incidentId: number | undefined;
  }>({ incidentId: 0 });

  const [, deleteIncidentMutation] = useMutation(DeleteIncidentMutation);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const handleDeleteDialog = () => {
    setOpenDeleteDialog(!openDeleteDialog);
  };

  const {
    data: { incidents: dataIncidents }
  } = useIncidentState({
    page: 1,
    perPage: 500,
    pause: false,
    query: ''
  });

  const idsOptions = dataIncidents?.map(({ id }) => ({
    value: String(id),
    label: String(id)
  }));

  const {
    data: { incidents: incidentCenterResult, total: incidentCenterTotal },
    fetching: isFetchingIncidents,
    reexecuteQuery: reexecuteIncidentsQuery
  } = useIncidentState({
    page,
    perPage: 10,
    query: customElasticQuery({
      customRegex: '\\*',
      queryFilters: [
        {
          key: 'title',
          value: search,
          isRegex: true
        },
        {
          key: 'description',
          value: search,
          isRegex: true
        },
        {
          key: 'id',
          value: shouldUseFilterWithResponders ? identifierFilter : ids,
          isRegex: false
        },
        {
          key: 'status',
          value: shouldUseFilterWithResponders ? statusFilter : status,
          isRegex: false
        },
        {
          key: 'severity',
          value: shouldUseFilterWithResponders ? severityFilter : severity,
          isRegex: false
        },
        {
          key: 'origin.uid',
          value: shouldUseFilterWithResponders ? originsFilter : origins,
          isRegex: false
        },
        {
          key: 'origin.responders',
          value: respondersFilter,
          isRegex: false
        },
        {
          key: 'tags.name',
          value: tagNameFilter,
          isRegex: false
        },
        {
          key: 'tags.value',
          value: tagValueFilter,
          isRegex: false
        }
      ]
    }),
    betweenKey: 'failure_happened_at',
    betweenValues: shouldUseFilterWithResponders ? betweenValuesFilter : betweenValues,
    pause: false
  });

  const deleteIncident = () => {
    const incident = incidentCenterResult.find(
      incident => incident.incidentId === incidentid.incidentId
    );

    deleteIncidentMutation(
      { incidentId: incidentid.incidentId },
      injectAuditTrailMetadata({
        title: incident ? incident.title : '',
        incidentId: incidentid.incidentId ? incidentid.incidentId.toString() : ''
      })
    ).then(() => {
      dispatch({
        type: actions.GLOBAL_SUCCESS,
        payload: 'Incident deleted successfully'
      });

      setTimeout(() => reexecuteQuery(), 2000);
    });

    setOpenDeleteDialog(false);
  };

  const reexecuteQuery = reexecuteIncidentsQuery;

  const newHandleApplyFilters = (filters: NewPageFilters) => {
    filters.status === 'all'
      ? removeQueryParams('status')
      : addQueryParams('status', filters.status);

    filters.severity === 'all'
      ? removeQueryParams('severity')
      : addQueryParams('severity', filters.severity);

    const originUids = filters?.origins?.map((origin: { value: string }) => origin.value);

    !filters.origins?.length
      ? removeQueryParams('origins')
      : addQueryParams('origins', originUids?.toString() || '');

    if (filters?.origins) {
      setOrigins(filters.origins.map((origin: { value: string }) => origin.value));
    }

    const originIds = filters?.ids?.map((id: { value: string }) => id.value);

    !filters.ids?.length
      ? removeQueryParams('ids')
      : addQueryParams('ids', originIds?.toString() || '');

    if (filters?.ids) {
      setIds(filters.ids.map((id: { value: string }) => id.value));
    }

    setStatus(filters.status as StatusEnum);
    setSeverity(filters.severity);
    setPage(1);

    reexecuteQuery();
  };

  const newHandleClearFilters = () => {
    removeQueryParams('status');
    removeQueryParams('severity');
    removeQueryParams('origins');
    removeQueryParams('ids');
    removeQueryParams('tagName');
    removeQueryParams('tagValue');

    setStatus('all');
    setSeverity('all');
    setOrigins([]);
    setIds([]);
    setTagNameFilter([]);
    setTagNameFilter([]);

    setPage(1);
    reexecuteQuery();
  };

  const hasIncidentManualPermission = usePermission(
    'IncidentController-post-/incidents/incident_manual'
  );

  const handleChangeSearch = useDebounce((event: ChangeEvent<HTMLInputElement>) => {
    const search = event.target.value;

    if (search.length === 0) return setSearch('');

    if (search.length < 3) return;

    setSearch(search);
  }, TYPING_DEBOUNCE_TIME);

  const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  useEffect(() => {
    reexecuteQuery();
  }, [page, reexecuteQuery]);

  const handleApplyPeriod = ({ period }: { period: string }) => {
    if (period === 'all') {
      removeQueryParams('period');
      setBetweenValues(`now-1y,now`);
      setPage(1);
      return;
    }

    addQueryParams('period', period);
    setBetweenValues(`now-${period},now`);
    setPage(1);
  };

  const handleApplyCustomRange = ({ betweenValue }: { betweenValue: string }) => {
    addQueryParams('period', betweenValue);
    setBetweenValues(betweenValue);
    setPage(1);
  };

  const handleClearCustomRange = () => {
    removeQueryParams('period');
    setBetweenValues('now-1y,now');
    setPage(1);
  };

  const appliedFilters = () => {
    return {
      status,
      severity,
      betweenValues: periodURL ? betweenValues : '',
      origins,
      ids
    };
  };

  const incidentStatusOptions = {
    label: 'Incident Status',
    options: [
      { value: 'all', label: 'All' },
      { value: StatusEnum.Alarmed, label: 'Alarmed' },
      { value: StatusEnum.Resolved, label: 'Resolved' },
      { value: StatusEnum.Acknowledged, label: 'Acknowledged' }
    ]
  };

  const [{ data: respondersData }] = useResponders();

  return (
    <>
      <Breadcrumb
        items={[{ label: 'Incident Management' }, { label: 'Incident Center', link: '/incidents' }]}
      />

      <Title title="Incident Center">
        <CreateManualIncidentButton disabled={!hasIncidentManualPermission} />
      </Title>

      {shouldUseFilterWithResponders ? (
        <Filters
          setSearch={setSearch}
          setPage={setPage}
          statusFilter={statusFilter}
          setStatusFilter={setStatusFilter}
          severityFilter={severityFilter}
          setSeverityFilter={setSeverityFilter}
          originsFilter={originsFilter}
          setOriginsFilter={setOriginsFilter}
          identifiersFilter={identifierFilter}
          identifierOptions={idsOptions}
          setIdentifierFilter={setIdentifierFilter}
          respondersFilter={respondersFilter}
          setRespondersFilter={setRespondersFilter}
          betweenValuesFilter={betweenValuesFilter}
          setBetweenValuesFilter={setBetweenValuesFilter}
          respondersData={respondersData!}
          setTagNameFilter={setTagNameFilter}
          setTagValueFilter={setTagValueFilter}
        />
      ) : (
        <EventsFilters
          handleChangeSearch={handleChangeSearch}
          onApplyFilters={newHandleApplyFilters}
          onApplyPeriod={handleApplyPeriod}
          onApplyCustomRange={handleApplyCustomRange}
          appliedFilters={appliedFilters}
          onClearFilters={newHandleClearFilters}
          onClearCustomRange={handleClearCustomRange}
          statusOptions={incidentStatusOptions}
          originsQuery={
            shouldUseNewApplications
              ? '(type: integration) OR (type: multiflow) OR (type: resource)'
              : '(type: integration) OR (type: multiflow)'
          }
          searchPlaceholder="Search by title or cause"
          idsOptions={idsOptions}
          hideId={false}
        />
      )}

      <Box>
        {isFetchingIncidents ? (
          <LoadingOverlay isBlock />
        ) : incidentCenterResult.length > 0 ? (
          <>
            <Divider className={classes.incidentDivider} />

            {incidentCenterResult.map(incident => {
              const filteredResponders = incident.responders.map(responder => {
                return respondersData?.getRespondersByOrgUid.data.find(
                  responderFetched => responder?.uid === responderFetched?.uid
                );
              }) as Responder[];

              return (
                <>
                  <Box key={incident?.incidentId} mt={3}>
                    <Box display="flex" alignItems="center">
                      <StatusChip
                        type="Severity"
                        severityStatus={incident.severity}
                        isAlertStatus={false}
                      />
                      <StatusChip type="Event" status={incident.status} isAlertStatus={true} />
                      <Link
                        className={classes.incidentTitle}
                        component={RouterLink}
                        to={`/incidents/${incident?.incidentId}`}
                        variant="h6">
                        {incident.title}
                      </Link>

                      <IncidentMenu incident={incident} reexecuteQuery={reexecuteQuery} />
                    </Box>

                    <Box display="flex">
                      <Typography variant="body2" className={classes.incidentCauseTitle}>
                        Cause: &nbsp;
                      </Typography>
                      <Typography
                        variant="body2"
                        title={incident.description}
                        className={classes.incidentCause}>
                        {reduceString(incident.description, 899)}
                      </Typography>
                    </Box>

                    <Box display="flex" alignItems="center" style={{ flexWrap: 'wrap' }}>
                      <Typography variant="body2" className={classes.incidentIdTitle}>
                        Incident ID: &nbsp;
                      </Typography>
                      <Link
                        className={classes.incidentIdLink}
                        component={RouterLink}
                        to={`/incidents/${incident.incidentId}`}
                        variant="body2">
                        #{incident?.incidentId}
                      </Link>

                      <span className={`${classes.ellipse} ${classes.firstChild}`}></span>

                      <Typography variant="body2" className={classes.incidentDateTitle}>
                        Started at: &nbsp;
                      </Typography>
                      <Typography variant="body2" className={classes.incidentDate}>
                        {format(parseISO(incident?.happenedAt), "dd/MM/yyyy HH:mm 'GMT-3'")} &nbsp;
                      </Typography>
                      {incident.status === StatusEnum.Resolved && (
                        <>
                          <span className={classes.ellipse}></span>

                          <Typography variant="body2" className={classes.incidentDateTitle}>
                            Ended at: &nbsp;
                          </Typography>
                          <Typography variant="body2" className={classes.incidentDate}>
                            {format(parseISO(incident?.updatedAt), "dd/MM/yyyy HH:mm 'GMT-3'")}
                          </Typography>
                        </>
                      )}

                      {shouldUseFilterWithResponders && incident?.responders?.length ? (
                        <ResponderTooltip responders={filteredResponders} />
                      ) : null}

                      {shouldUseTagsFilter && incident.tags && incident.tags.length > 0 && (
                        <TagsTooltip tags={incident.tags} />
                      )}
                    </Box>
                  </Box>

                  <Divider className={classes.incidentDivider} />
                </>
              );
            })}

            <Box display="flex" justifyContent="center">
              <Pagination
                page={page}
                count={Math.ceil(incidentCenterTotal / 10)}
                size="large"
                color="primary"
                onChange={handleChangePage}
              />
            </Box>
          </>
        ) : (
          <Typography variant="h6" className={classes.textNoResult}>
            No results found
          </Typography>
        )}

        <DeleteEntity
          open={openDeleteDialog}
          entityName="Incident"
          handleClose={handleDeleteDialog}
          disabled={false}
          isLoading={false}
          handleRemove={deleteIncident}
        />
      </Box>
    </>
  );
}
