import { ChangeEvent } from 'react';

import { Box, Button } from '@material-ui/core';
import { Check } from '@material-ui/icons';
import { UseFormMethods } from 'react-hook-form';

import { Input } from 'views/ServicesHub/components/Input';
import { FormValues } from 'views/ServicesHub/layouts/StatusPage';

export type CustomDomainSettingsFormProps = {
  form: UseFormMethods<FormValues>;
};

export function CustomDomainSettingsForm({ form }: CustomDomainSettingsFormProps) {
  const { register, setValue, watch } = form;

  const customDomainValue = watch('customDomain');

  const onCustomDomainChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setValue('customDomain', value);
    setValue('enterCustomDomain', value);
  };

  const onVerify = () => {};

  return (
    <Box display="flex" gridGap="2rem" flexDirection="column">
      <Box display="flex" gridGap="2rem" flexDirection="row">
        <Input
          label="Enter custom domain"
          name="customDomain"
          inputRef={register}
          errors={form.errors}
          required={false}
          onChange={onCustomDomainChange}
        />

        <Input
          label="DNS Records"
          name="dnsRecords"
          inputRef={register}
          errors={form.errors}
          required={false}
        />
      </Box>

      <Box width="100%" display="flex" gridGap="1rem">
        <Input
          label="Enter custom domain"
          name="enterCustomDomain"
          inputRef={register}
          required={false}
        />

        <Button variant="outlined" color="primary" disabled={!customDomainValue} onClick={onVerify}>
          <Check /> VERIFY
        </Button>
      </Box>
    </Box>
  );
}
