import { apm } from '@elastic/apm-rum';
import { zodResolver } from '@hookform/resolvers/zod';
import { Box, Button, Divider, Tooltip, Typography } from '@material-ui/core';
import { HelpOutlineOutlined } from '@material-ui/icons';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import { LoadingOverlay } from 'componentsV4/Loading';
import { injectAuditTrailMetadata } from 'helpers/injectAuditTrailMetadata';
import { useStatusPage } from 'hooks/queriesGraphQL/useStatusPage';
import useFeatureFlagUnleash from 'hooks/useFeatureFlagUnleash';
import actions from 'redux/actions';
import { LayoutType } from 'views/ServicesHub/forms';

import { BrandCustomization } from './BrandCustomization';
import { ComponentsSettings } from './ComponentsSettings';
import { CustomDomainSettings } from './CustomDomainSettings';
import { schema } from './schema';
import { useStyles } from './styles';

export type FormValues = {
  name: string;
  slug: string;
  cover?: FormCoverValues;
  subtitle?: string;
  passphrase: string;
  enableSubscription: boolean;

  customDomain?: string;
  dnsRecords?: string;
  backgroundColorHeader?: string;
  textColorHeader?: string;
  textColorPage?: string;
  textColorTitle?: string;

  groups: FormGroupValues[];
};

type FormCoverValues = {
  file: string;
  fileName: string;
  contentType: string;
};

type FormGroupValues = {
  name: string;
  services: FormServiceValues[];
};

export type FormServiceValues = {
  name: string;
  displayName?: string;
  useResource?: boolean;
  resourceUid?: string;
  displayEnabled: boolean;
  monitoringId: number;
  syntheticUid?: string;
  type: 'synthetic' | 'external' | 'resource';
};

export function StatusPage({ Form, isEdit }: LayoutType) {
  const classes = useStyles();

  const history = useHistory();
  const dispatch = useDispatch();

  const useDomainSettings = useFeatureFlagUnleash('useDomainSettings', { queryString: true });

  const { isCreating, createStatusPage } = useStatusPage();

  const form = useForm<FormValues>({
    mode: 'all',
    defaultValues: {
      name: '',
      slug: '',
      passphrase: '',
      groups: [{ name: '', services: [] }]
    },
    resolver: zodResolver(schema)
  });

  const { reset } = form;

  const handleSubmit = async (data: FormValues) => {
    if (isEdit) return;

    const statusPageResponse = await createStatusPage(
      { data },
      injectAuditTrailMetadata({ name: data.name })
    );

    if (statusPageResponse.error) {
      dispatch({
        type: actions.ENTITY_ERROR,
        payload: { message: 'Error on create status page' }
      });

      // eslint-disable-next-line no-console
      console.error(statusPageResponse.error);
      apm.captureError(statusPageResponse.error);
      return;
    }

    dispatch({
      type: actions.GLOBAL_SUCCESS,
      payload: 'Team created successfully'
    });

    reset();

    history.push(`/statuspages`);
  };

  return (
    <Box
      component="form"
      display="flex"
      flex={1}
      gridGap="2.5rem"
      flexDirection="column"
      position="relative"
      onSubmit={form.handleSubmit(handleSubmit)}>
      {isCreating && <LoadingOverlay />}

      <Typography variant="h4" className={classes.title}>
        Basic informations{' '}
        <Tooltip title="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and Lorem Ipsum has been the industry's">
          <HelpOutlineOutlined style={{ color: '#5E5E5E' }} />
        </Tooltip>
      </Typography>

      <Box display="flex" gridGap="3rem">
        <Box flex={2}>
          <Box display="flex" gridGap="2rem" flexDirection="column">
            <Form form={form} />
          </Box>
        </Box>
      </Box>

      {useDomainSettings && (
        <>
          <Divider />

          <CustomDomainSettings form={form} />
        </>
      )}

      <Divider />

      <BrandCustomization form={form} />

      <Divider />

      <ComponentsSettings form={form} />

      <Divider />

      <Box display="flex" gridGap="1rem">
        <Button
          variant="outlined"
          color="primary"
          onClick={() => history.goBack()}
          disabled={isCreating}>
          CANCEL
        </Button>

        <Button variant="contained" color="primary" type="submit" disabled={isCreating}>
          {isEdit ? 'EDIT' : 'SAVE'} STATUS PAGE
        </Button>
      </Box>
    </Box>
  );
}
