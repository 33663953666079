import { useState } from 'react';

import { Box, Divider, IconButton } from '@material-ui/core';
import { Delete as DeleteIcon, Lock, LockOpen } from '@material-ui/icons';
import { format } from 'date-fns';
import DeleteDialogV2 from 'oldComponents/DeleteDialogV2';
import { Typography } from 'oldComponents/Typography';
import { SeverityStatusEnum } from 'types/external/Severity';
import { StatusEnum } from 'types/external/Status';

import getCapitalizeFirstLetter from 'common/getCapitalizeFirstLetter';
import { injectAuditTrailMetadata } from 'helpers/injectAuditTrailMetadata';
import useIncidentStatusUpdate from 'hooks/queriesGraphQL/useIncidentStatusUpdate';
import { IncidentUpdate } from 'hooks/queriesGraphQL/useIncidentUpdate';
import { usePermission } from 'hooks/usePermission';
import { StatusChip } from 'views/AlertCenter/components/StatusChip';

import { useStyles } from './styles';

type UpdateProps = {
  incident: any;
  update: IncidentUpdate;
  onDelete: () => void;
  onUpdate: () => void;
};

const getDescription = (incident: any, update: IncidentUpdate, classes: any): JSX.Element => {
  switch (update.type) {
    case 'IncidentStatusUpdate': {
      return (
        <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center">
          <span className={classes.ellipseSeparator}></span>
          <Typography variant="body2" className={classes.descriptionUpdateLine}>
            {String(update.incidentStatusUpdate?.description)}
          </Typography>
        </Box>
      );
    }
  }

  return <></>;
};

function getStatusEnumByStatus(status: string) {
  const statusMap: { [key: string]: StatusEnum } = {
    Alarmed: StatusEnum.Alarmed,
    Resolved: StatusEnum.Resolved,
    Acknowledged: StatusEnum.Acknowledged,
    PostMortem: StatusEnum.PostMortem
  };

  return statusMap[status];
}

const getStatusChip = (update: IncidentUpdate) => {
  switch (update.type) {
    case 'IncidentStatusUpdate': {
      return (
        <StatusChip
          type="IncidentStatusUpdate"
          isAlertStatus={false}
          isPrivate={update?.incidentStatusUpdate?.private}
          style={{ height: '24px', marginRight: '8px' }}
        />
      );
    }
    case 'Event': {
      return (
        <StatusChip
          type="Event"
          status={getStatusEnumByStatus(String(update?.event?.type))}
          isAlertStatus={true}
          style={{ height: '24px', marginRight: '8px' }}
        />
      );
    }
  }

  return <Divider />;
};

const getSeverityChip = (update: IncidentUpdate): JSX.Element => {
  if (update.type === 'Event') {
    return (
      <StatusChip
        type="Severity"
        severityStatus={update.event?.content?.severity as SeverityStatusEnum}
        isAlertStatus={false}
        style={{ height: '24px', marginRight: '8px' }}
      />
    );
  }

  return <Divider />;
};

const isValideDate = (date: string): Boolean => {
  var timestamp = Date.parse(date);

  return !isNaN(timestamp);
};

const getGenerated = (incident: any, update: IncidentUpdate, classes: any): JSX.Element => {
  switch (update.type) {
    case 'IncidentStatusUpdate': {
      if (isValideDate(String(update.incidentStatusUpdate?.createdAt))) {
        const eventDate = format(
          new Date(String(update.incidentStatusUpdate?.createdAt)),
          "MMMM, dd, HH:mm 'GMT-3'"
        );

        const updatedAction = getCapitalizeFirstLetter(
          String(update?.incidentStatusUpdate?.lastAction.action)
        );

        const displayName = update?.incidentStatusUpdate?.lastAction.user?.name;
        let description = displayName
          ? `${updatedAction} by ${displayName}`
          : 'Automatically generated';

        return (
          <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center">
            <span className={classes.ellipseSeparator}></span>
            <Typography variant="body2" className={classes.generatedUpdateLine}>
              {eventDate}
            </Typography>
            <span className={classes.ellipseSeparator} style={{ marginLeft: '5px' }}></span>
            <Typography variant="body2" className={classes.generatedUpdateLine}>
              {description}
            </Typography>
          </Box>
        );
      }

      return <></>;
    }
    case 'Event': {
      if (isValideDate(String(update.event?.createdAt))) {
        const eventDate = format(
          new Date(String(update.event?.createdAt)),
          "MMMM, dd, HH:mm 'GMT-3'"
        );

        const type = update?.event?.type;
        const displayName = update?.event?.content?.user?.name;

        const description =
          displayName && type
            ? `${type} by ${displayName}`
            : type === 'Acknowledged'
            ? ''
            : 'Automatically generated';

        return (
          <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center">
            <span className={classes.ellipseSeparator}></span>
            <Typography variant="body2" className={classes.generatedUpdateLine}>
              {eventDate}
            </Typography>
            {description !== '' && (
              <>
                <span className={classes.ellipseSeparator} style={{ marginLeft: '5px' }}></span>
                <Typography variant="body2" className={classes.generatedUpdateLine}>
                  {description}
                </Typography>
              </>
            )}
          </Box>
        );
      }

      return <></>;
    }
  }
};

export function Update({ incident, update, onDelete, onUpdate }: UpdateProps) {
  const classes = useStyles();

  const [isPrivate, setIsPrivate] = useState(update?.incidentStatusUpdate?.private);
  const { deleteIncidentStatusUpdate, updateIncidentStatusUpdate } = useIncidentStatusUpdate();

  const [
    { fetching: isDeletingIncidentStatusUpdateMutation },
    deleteIncidentStatusUpdateMutation
  ] = deleteIncidentStatusUpdate;

  const [
    { fetching: isUpdatingIncidentStatusUpdateMutation },
    updateIncidentStatusUpdateMutation
  ] = updateIncidentStatusUpdate;

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [deleteIncidentStatusUpdateData, setDeleteIncidentStatusUpdateData] = useState<{
    incidentId: number | undefined;
    id: number | undefined;
    description: string | undefined;
  }>({
    incidentId: update.incidentStatusUpdate?.id,
    id: update.incidentStatusUpdate?.incidentId,
    description: update.incidentStatusUpdate?.description
  });

  function handleDeleteIncidentStatusUpdate({
    incidentId,
    id,
    description
  }: {
    incidentId: number | undefined;
    id: number | undefined;
    description: string | undefined;
  }) {
    setDeleteIncidentStatusUpdateData({ incidentId, id, description });
    setIsDeleteDialogOpen(true);
  }

  async function toggleVisibility({
    incidentId,
    id
  }: {
    incidentId: number | undefined;
    id: number | undefined;
  }) {
    const { data } = await updateIncidentStatusUpdateMutation({
      incidentId,
      id,
      data: {
        description: update?.incidentStatusUpdate?.description,
        private: !update?.incidentStatusUpdate?.private
      }
    });

    if (data?.updateIncidentStatusUpdate) {
      setIsPrivate(prev => !prev);
    }
  }

  async function deleteIncidentStatusUpdateConfirm() {
    await deleteIncidentStatusUpdateMutation(
      {
        incidentId: deleteIncidentStatusUpdateData.incidentId,
        id: deleteIncidentStatusUpdateData.id
      },
      injectAuditTrailMetadata({
        title: deleteIncidentStatusUpdateData.description!,
        incidentId: deleteIncidentStatusUpdateData.incidentId!.toString()
      })
    );
    setIsDeleteDialogOpen(false);
    onDelete();
  }

  const hasUpdateIncidentStatusUpdatePermission = usePermission(
    'IncidentStatusUpdateController-put-/status_updates'
  );
  const hasDeleteIncidentStatusUpdatePermission = usePermission(
    'IncidentStatusUpdateController-delete-/status_updates'
  );

  return (
    <>
      <DeleteDialogV2
        open={isDeleteDialogOpen}
        onClose={() => setIsDeleteDialogOpen(false)}
        onPrimaryAction={deleteIncidentStatusUpdateConfirm}
        message="By deleting this incident status uptade, it will be extinct."
        isLoading={isDeletingIncidentStatusUpdateMutation}
        title="Delete Incident Update?"
        primaryActionLabel="Delete Incident Update"
      />
      <Box display="block" gridGap={10}>
        <Box display="flex" alignItems="center">
          <span className={classes.ellipse} />
          {getSeverityChip(update)}

          {getStatusChip(update)}

          <Box
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            marginLeft={0.5}>
            {getDescription(incident, update, classes)}
          </Box>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            marginLeft={0.5}>
            {getGenerated(incident, update, classes)}
          </Box>
          {update.type === 'IncidentStatusUpdate' && hasUpdateIncidentStatusUpdatePermission && (
            <IconButton
              disabled={isUpdatingIncidentStatusUpdateMutation}
              onClick={() =>
                toggleVisibility({
                  incidentId: update.incidentStatusUpdate?.incidentId,
                  id: update.incidentStatusUpdate?.id
                })
              }>
              {isPrivate ? (
                <Lock className={classes.toggleVisibilityIcon} />
              ) : (
                <LockOpen className={classes.toggleVisibilityIcon} />
              )}
            </IconButton>
          )}

          {update.type === 'IncidentStatusUpdate' && hasDeleteIncidentStatusUpdatePermission && (
            <IconButton
              onClick={() =>
                handleDeleteIncidentStatusUpdate({
                  incidentId: update.incidentStatusUpdate?.incidentId,
                  id: update.incidentStatusUpdate?.id,
                  description: update.incidentStatusUpdate?.description
                })
              }>
              <DeleteIcon className={classes.deleteIcon} />
            </IconButton>
          )}
        </Box>
        <Box display="flex" alignItems="center">
          <div className={classes.line} />
        </Box>
      </Box>
    </>
  );
}
