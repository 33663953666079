import { Autocomplete } from '@material-ui/lab';
import classNames from 'classnames';

import { Input } from 'views/ServicesHub/components/Input';

import { useStyles } from './styles';

interface Option {
  label: string;
  type?: string;
}

interface AutocompleteProps<T extends Option> {
  options: T[];
  handleChangeOption: (value: T[]) => void;
  orderedOptions: T[];
  getOptionLabel: (option: T) => string;
  getOptionSelected: (option: T, value: T) => boolean;
  label: string;
}

function CustomAutocomplete<T extends Option>({
  options,
  handleChangeOption,
  orderedOptions,
  getOptionLabel,
  getOptionSelected,
  label
}: AutocompleteProps<T>) {
  const classes = useStyles();

  return (
    <Autocomplete
      multiple
      value={options}
      onChange={(_, value) => handleChangeOption(value as T[])}
      getOptionLabel={getOptionLabel}
      getOptionSelected={getOptionSelected}
      options={orderedOptions}
      size="small"
      fullWidth
      ChipProps={{
        color: 'secondary'
      }}
      renderOption={(option: T) => {
        return (
          <>
            {option.label}
            {option.type ? <span className={classes.helperText}>{option.type}</span> : null}
          </>
        );
      }}
      renderInput={params => (
        <Input
          {...params}
          label={label}
          required={false}
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            className: classNames(classes.autocomplete, params.InputProps?.className)
          }}
          InputLabelProps={{
            shrink: true,
            color: 'secondary'
          }}
        />
      )}
    />
  );
}

export default CustomAutocomplete;
