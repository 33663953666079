import { ChangeEvent, useRef, useState } from 'react';

import { Box, Button, Typography } from '@material-ui/core';
import { CloudDownloadOutlined } from '@material-ui/icons';
import { UseFormMethods } from 'react-hook-form';

import { ImageAdjustDialog } from './ImageAdjustDialog';
import { useStyles } from './style';

type UploadImageProps = {
  name?: string;
  title: string;
  register?: UseFormMethods['register'];
  onChange?: (file: File) => void;
};

export function UploadImage({ title, name, onChange, register }: UploadImageProps) {
  const inputRef = useRef<HTMLInputElement | null>(null);

  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [imageBase64, setImageBase64] = useState<string | null>(null);
  const [adjustImage, setAdjustImageBase64] = useState<string | null>(null);
  const [openDialog, setopenDialog] = useState<boolean>(false);
  const [originalFileName, setOriginalFileName] = useState<string | null>(null);

  const classes = useStyles({ isDragging });

  const onFileLoad = (e: ProgressEvent<FileReader>, file: File) => {
    setImageBase64(e.target?.result as string);
  };

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setIsDragging(false);

    const file = e.target.files && e.target.files.length > 0 ? e.target.files[0] : null;
    if (file) {
      setOriginalFileName(file.name);
      const reader = new FileReader();
      reader.onload = e => onFileLoad(e, file);
      reader.readAsDataURL(file);

      setopenDialog(true);
    }
  };

  const onClick = () => {
    inputRef.current?.click();
  };

  const onDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const onDragLeave = () => {
    setIsDragging(false);
  };

  const onDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragging(false);

    const file =
      e.dataTransfer.files && e.dataTransfer.files.length > 0 ? e.dataTransfer.files[0] : null;
    if (file) {
      setOriginalFileName(file.name);
      const reader = new FileReader();
      reader.onload = e => onFileLoad(e, file);
      reader.readAsDataURL(file);

      setopenDialog(true);
    }
  };

  const onCloseDialog = () => {
    setopenDialog(false);
  };

  const base64ToFile = (base64: string, filename: string): File => {
    const arr = base64.split(',');
    const mime = arr[0].split(':')[1]?.split(';')[0] || 'image/png';
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  };

  const onUpdate = (resizedImageBase64: string) => {
    setAdjustImageBase64(resizedImageBase64);

    const file = base64ToFile(resizedImageBase64, originalFileName || 'banner.png');

    if (register)
      register({
        name: 'cover',
        value: { file: resizedImageBase64, fileName: file.name, contentType: file.type }
      });
    if (onChange) onChange(file);
  };

  return (
    <>
      <input
        accept="image/*"
        name={name}
        disabled={false}
        onChange={onInputChange}
        className={classes.inputFile}
        ref={e => {
          inputRef.current = e;
        }}
        type="file"
      />

      <Box>
        <Box
          className={classes.dragAndDropBox}
          style={{ cursor: 'pointer' }}
          onClick={onClick}
          onDragOver={onDragOver}
          onDragLeave={onDragLeave}
          onDrop={onDrop}>
          {!adjustImage ? (
            <>
              <Typography>Drag and drop your image here or click select file</Typography>
              <Typography style={{ fontWeight: 'bold' }}>(Suggested Size 850 x 315 px)</Typography>
            </>
          ) : (
            <img src={adjustImage} alt="Image_uploaded" className={classes.image} />
          )}
        </Box>

        <Button
          variant="outlined"
          color="primary"
          startIcon={<CloudDownloadOutlined />}
          onClick={onClick}>
          SELECT FILE
        </Button>
      </Box>

      <ImageAdjustDialog
        title={title}
        open={openDialog}
        imageBase64={imageBase64}
        onClose={onCloseDialog}
        onUpdate={onUpdate}
      />
    </>
  );
}
