import {
  Box,
  CardContent,
  Chip,
  Divider,
  Link,
  Paper,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core';

import { IncidentTags as IncidentTagsType } from 'hooks/queriesGraphQL/useGetIncidentTags';
import useFeatureFlagUnleash from 'hooks/useFeatureFlagUnleash';

import { useStyles } from './styles';

type IncidentTagsProps = {
  tags: IncidentTagsType[];
};

function IncidentTags({ tags }: IncidentTagsProps) {
  const theme = useTheme();

  const shouldUseTagsFilter = useFeatureFlagUnleash('useTagsFilter', {
    queryString: true
  });

  const isDesktop = useMediaQuery(theme.breakpoints.up('xl'), {
    defaultMatches: true
  });

  const classes = useStyles({ maxWidth: isDesktop ? 500 : 300 });

  const tagValueCharacters = isDesktop ? 80 : 40;

  return (
    <CardContent className={classes.cardContent}>
      <Paper elevation={1} className={classes.paper}>
        <Typography className={classes.titleCard} variant="subtitle2">
          Tags
        </Typography>
        <Divider className={classes.dividerSections} variant="fullWidth" />

        <Box
          display="flex"
          gridGap={theme.spacing()}
          mt={1}
          style={{ flexWrap: 'wrap', maxHeight: 80, overflowY: 'auto' }}>
          {tags.map(tag => (
            <Box display="flex" gridGap={theme.spacing()} key={tag.id}>
              {shouldUseTagsFilter ? (
                <Link href={`/incidents?tagName=${tag.name}&tagValue=${tag.value}`}>
                  <Tooltip
                    title={
                      tag.name.length + tag.value.length > tagValueCharacters
                        ? `${tag.name}: ${tag.value}`
                        : ''
                    }>
                    <Chip
                      label={`${tag.name}: ${tag.value}`}
                      className={classes.chip}
                      style={{ cursor: 'pointer' }}
                    />
                  </Tooltip>
                </Link>
              ) : (
                <Tooltip
                  title={
                    tag.name.length + tag.value.length > tagValueCharacters
                      ? `${tag.name}: ${tag.value}`
                      : ''
                  }>
                  <Chip label={`${tag.name}: ${tag.value}`} className={classes.chip} />
                </Tooltip>
              )}
            </Box>
          ))}
        </Box>
      </Paper>
    </CardContent>
  );
}

export default IncidentTags;
